import { Divider, Modal } from "@mui/material";
import { CloseButton } from "../base/closeButton";
import React from "react";
import { StyledButton } from "../base/styledButton";
import { SectionTitle } from "../base/sectionTitle";
import { SectionSubtitle } from "../base/sectionSubtitle";
import { ModalWrapper } from "../base/modalComponents";

export const ExportGpxModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.exportModalOpen) {
        props.handleExportModalOpen(); // Call the function to close the modal
      }
    }
  };
  return (
    <Modal open={props.exportModalOpen} key="notes" onKeyDown={handleKeyDown}>
      <div>
        <ModalWrapper>
          <CloseButton close_action={props.handleExportModalOpen} />
          <SectionTitle title_text={props.title_text} />
          <Divider />
          {props.modalPage === 1 ? (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SectionSubtitle subtitle_text={props.page_1_text} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "2vh",
                }}
              >
                <SectionSubtitle subtitle_text={"Selected Sequence:"} />
                <input
                  type="radio"
                  value="Sequence"
                  name="Sequence"
                  onChange={(e) => props.handleSetExportSelection(e)}
                  checked={
                    props.exportSelection !== null
                      ? props.exportSelection === "Sequence"
                      : false
                  }
                />
                <span style={{ width: "5vw" }} />
                <SectionSubtitle subtitle_text={"Entire Project:"} />
                <input
                  type="radio"
                  value="Project"
                  name="Project"
                  onChange={(e) => props.handleSetExportSelection(e)}
                  checked={
                    props.exportSelection !== null
                      ? props.exportSelection === "Project"
                      : false
                  }
                  style={{ marginRight: "6.5vw" }}
                />
              </div>
            </>
          ) : props.modalPage === 2 ? (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SectionSubtitle subtitle_text={props.page_2_text} />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "2vh",
                }}
              >
                <SectionSubtitle subtitle_text={"Front:"} />
                <input
                  type="radio"
                  value="Front"
                  name="Front"
                  onChange={(e) => props.handleSetExportView(e)}
                  checked={props.exportView && props.exportView === "Front"}
                />
                <span style={{ width: "5vw" }} />
                <SectionSubtitle subtitle_text={"Left:"} />
                <input
                  type="radio"
                  value="Left"
                  name="Left"
                  onChange={(e) => props.handleSetExportView(e)}
                  checked={props.exportView && props.exportView === "Left"}
                />
                <span style={{ width: "5vw" }} />
                <SectionSubtitle subtitle_text={"Right:"} />
                <input
                  type="radio"
                  value="Right"
                  name="Right"
                  onChange={(e) => props.handleSetExportView(e)}
                  checked={props.exportView && props.exportView === "Right"}
                />
                <span style={{ width: "5vw" }} />
                <SectionSubtitle subtitle_text={"360:"} />
                <input
                  type="radio"
                  value="360"
                  name="360"
                  onChange={(e) => props.handleSetExportView(e)}
                  checked={props.exportView && props.exportView === "360"}
                />
              </div>
            </>
          ) : props.modalPage === 3 ? (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SectionSubtitle subtitle_text={"Confirm GPX Export Details"} />
              </div>
              <Divider />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SectionSubtitle
                  subtitle_text={`Export Selection: ${props.exportSelection}`}
                />
                <SectionSubtitle
                  subtitle_text={`Export View Direction: ${
                    props.exportSelection === "Sequence"
                      ? props.selectedView
                      : props.exportView
                  }`}
                />
              </div>
            </>
          ) : (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SectionSubtitle subtitle_text={"GPX has been downloaded"} />
              </div>
            </>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              marginBottom: "2vh",
            }}
          >
            <Divider />
            <StyledButton
              button_action={
                props.modalPage === 1
                  ? () => props.handleSetModalPage(2)
                  : props.modalPage === 2
                  ? () => props.handleSetModalPage(3)
                  : props.modalPage === 3
                  ? () => props.handleSetModalPage(4)
                  : () => props.handleSetModalPage(5)
              }
              button_text={
                props.modalPage !== 3
                  ? "Next"
                  : props.modalPage === 3
                  ? "Confirm"
                  : props.modalPage === 4
                  ? "Close"
                  : ""
              }
            />
            {props.modalPage !== 4 ? (
              <>
                <StyledButton
                  button_action={props.handleExportModalOpen}
                  button_text={"Cancel"}
                />
              </>
            ) : null}
          </div>
        </ModalWrapper>
      </div>
    </Modal>
  );
};
