//GENERAL STYLED BUTTON
import { Button, ButtonLabel } from "./styles";
import React from "react";

export const StyledButton = (props) => {
  return (
    <Button
      onClick={props.button_action}
      style={{
        textAlign: "center",
        lineHeight: "2.0em",
      }}
    >
      <ButtonLabel>{props.button_text}</ButtonLabel>
    </Button>
  );
};
