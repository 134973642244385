import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { DataContext } from "../../../Contexts/DataContext";
import { Input } from "../../base/styles";
import { createTrip } from "../../../services/tripService";
import { SectionSubtitle } from "../../base/sectionSubtitle";
import { checkS3Connection } from "../../../services/cloudService";
import { BaseModal } from "../../composite/BaseModal";

export function AddTripModal({ addOpen, toggleAddOpen }) {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    city: "",
    country: "",
    totalVehicles: 0,
    startDate: "",
    endDate: "",
    bucket: "",
    endpoint: "",
    token: "",
    secret: "",
    region: "",
  });

  const { handleFetchOrgTrips } = useContext(DataContext);

  useEffect(() => {
    if (!addOpen) {
      setFormData({
        name: "",
        city: "",
        country: "",
        totalVehicles: 0,
        startDate: "",
        endDate: "",
        bucket: "",
        endpoint: "",
        token: "",
        secret: "",
        region: "",
      });
      setStep(1);
    }
  }, [addOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (step < 2) {
    //   setStep(step + 1);
    //   return;
    // }

    try {
      const response = await createTrip(
        formData.name,
        formData.country,
        formData.city,
        formData.totalVehicles,
        formData.startDate,
        formData.endDate,
        formData.bucket,
        formData.endpoint,
        formData.token,
        formData.secret,
        formData.region
      );

      if (response.status === 200) {
        handleFetchOrgTrips("new");
        toggleAddOpen(false);
      }
    } catch (error) {
      console.error("Failed to create trip:", error);
    }
  };

  const [isTestingConnection, setIsTestingConnection] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState(null);

  const handleTestConnection = async () => {
    setIsTestingConnection(true);
    setConnectionStatus(null);

    const success = await checkS3Connection(
      formData.bucket,
      formData.endpoint,
      formData.token,
      formData.secret,
      formData.region
    );

    setConnectionStatus(success ? "success" : "error");
    setIsTestingConnection(false);
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Input
              type="text"
              name="name"
              placeholder="Trip Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <Input
              type="text"
              name="country"
              placeholder="Country"
              value={formData.country}
              onChange={handleChange}
              required
            />
            <Input
              type="text"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleChange}
            />
            <div style={{ display: "flex", gap: "10px" }}>
              <div style={{ flex: 1 }}>
                <SectionSubtitle subtitle_text="Start Date" />
                <Input
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleChange}
                  required
                />
              </div>
              <div style={{ flex: 1 }}>
                <SectionSubtitle subtitle_text="End Date" />
                <Input
                  type="date"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleChange}
                  required
                  min={formData.startDate}
                />
              </div>
            </div>
          </>
        );
      // case 2:
      //   return (
      //     <>
      //       <Input
      //         type="text"
      //         name="bucket"
      //         placeholder="S3 Bucket"
      //         value={formData.bucket}
      //         onChange={handleChange}
      //         required
      //       />
      //       <Input
      //         type="text"
      //         name="endpoint"
      //         placeholder="S3 Endpoint"
      //         value={formData.endpoint}
      //         onChange={handleChange}
      //         required
      //       />
      //       <Input
      //         type="text"
      //         name="token"
      //         placeholder="Access Token"
      //         value={formData.token}
      //         onChange={handleChange}
      //         required
      //       />
      //       <Input
      //         type="password"
      //         name="secret"
      //         placeholder="Secret Key"
      //         value={formData.secret}
      //         onChange={handleChange}
      //         required
      //       />
      //       <Input
      //         type="text"
      //         name="region"
      //         placeholder="Region"
      //         value={formData.region}
      //         onChange={handleChange}
      //         required
      //       />
      //       <div
      //         style={{
      //           marginTop: "16px",
      //           display: "flex",
      //           alignItems: "center",
      //           gap: "12px",
      //         }}
      //       >
      //         <button
      //           type="button"
      //           onClick={handleTestConnection}
      //           disabled={
      //             isTestingConnection ||
      //             !formData.bucket ||
      //             !formData.endpoint ||
      //             !formData.token ||
      //             !formData.secret ||
      //             !formData.region
      //           }
      //         >
      //           {isTestingConnection ? "Testing..." : "Test Connection"}
      //         </button>

      //         {connectionStatus && (
      //           <span
      //             style={{
      //               color: connectionStatus === "success" ? "green" : "red",
      //             }}
      //           >
      //             {connectionStatus === "success"
      //               ? "✓ Connection successful"
      //               : "✕ Connection failed"}
      //           </span>
      //         )}
      //       </div>
      //     </>
      //   );
      default:
        return null;
    }
  };

  const stepTitles = {
    1: "Trip Details",
    // 2: "Storage Configuration",
  };

  return (
    <BaseModal
      isOpen={addOpen}
      onClose={() => toggleAddOpen(false)}
      
      title={`Create New Trip - ${stepTitles[step]}`}
      confirmText={"Create"}
      // confirmText={step === 2 ? "Create" : "Next"}
      onConfirm={handleSubmit}
      showBack={step > 1}
      onBack={() => setStep(step - 1)}
    >
      <form onSubmit={handleSubmit}>{renderStepContent()}</form>
    </BaseModal>
  );
}
