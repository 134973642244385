import { poster } from "../calls";

export async function deleteDay(day_id, delete_originals) {
  try {
    return await poster(
      {
        day_id: day_id,
        delete_originals: delete_originals,
      },
      "/delete_day"
    );
  } catch (error) {
    console.error(error);
  }
}

export async function getDaysByVehicleId(vehicle_id) {
  try {
    return await poster(
      {
        vehicle_id: vehicle_id,
      },
      "project/fetch_vehicle_days"
    );
  } catch (error) {
    console.error(error);
  }
}
