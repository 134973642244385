import React from "react";
import { BaseModal } from "../../composite/BaseModal";

export const DeleteTripModal = (props) => {
  return (
    <BaseModal
      isOpen={props.deleteOpen}
      onClose={props.handleDeleteOpen}
      title={`Delete Trip: ${props.tripName}`}
      subtitle="Are you sure you want to delete the following trip?"
      confirmText="Delete"
      onConfirm={props.handle_delete_trip}
    />
  );
};
