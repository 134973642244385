import { API_URL } from "./components/constants";
import { useContext } from "react";
import { AuthContext } from "./Contexts/AuthContext";
var obj;

//--------------poster----------
export async function poster(
  info,
  route,
  method = "POST",
  responseType = "json"
) {
  try {
    const response = await fetch(API_URL.concat(route), {
      method,
      responseType,
      body: JSON.stringify(info),
      headers: {
        "Content-Type": "application/json",
        credentials: "include",
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      // Handle non-OK responses centrally
      await HandleResponseError(response);
      return { response: "error", status: response.status };
    }
  } catch (error) {
    // Handle fetch errors centrally
    await handleNetworkError(error);
    return { response: "error" };
  }
}

//--------------fetcher---------
export async function fetcher(route) {
  try {
    const response = await fetch(API_URL.concat(route), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      obj = await response.json();
    } else if (!response.ok) {
      obj = { response: "Error" };
    }

    return obj;
  } catch (error) {
    // Handle fetch errors here
    console.error("Failed to fetch:", error);
    return { response: "error" };
  }
}

async function HandleResponseError(response) {
  const { logout } = useContext(AuthContext);
  if (response.status === 304) {
    logout();
  } else if (response.status === 524) {
    alert("RequestTimeout: Please try again later.");
  }
}

async function handleNetworkError(error) {
  console.log("Network error:", error);
}
