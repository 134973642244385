// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Table Styles */
.mt-12 {
  left: 10px;
  margin-top: 275px;
}

.custom-table {
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}

/* Header Styles */
.custom-table-header {
  background-color: #f4753c; /* Light gray background */
  color: #ffffff;
}

.custom-table-row:hover {
  background-color: #eaeaeaff; /* Light gray hover effect */
}

.custom-table-cell {
  padding: 0.75rem 1rem; /* Padding for cells */
  text-align: left;
}

.bg-blue-100 {
  background-color: #dbeafe;
}
`, "",{"version":3,"sources":["webpack://./src/components/composite/table/style.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA,kBAAkB;AAClB;EACE,yBAAyB,EAAE,0BAA0B;EACrD,cAAc;AAChB;;AAEA;EACE,2BAA2B,EAAE,4BAA4B;AAC3D;;AAEA;EACE,qBAAqB,EAAE,sBAAsB;EAC7C,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* Table Styles */\n.mt-12 {\n  left: 10px;\n  margin-top: 275px;\n}\n\n.custom-table {\n  width: 100%;\n  overflow: hidden;\n  border-radius: 5px;\n}\n\n/* Header Styles */\n.custom-table-header {\n  background-color: #f4753c; /* Light gray background */\n  color: #ffffff;\n}\n\n.custom-table-row:hover {\n  background-color: #eaeaeaff; /* Light gray hover effect */\n}\n\n.custom-table-cell {\n  padding: 0.75rem 1rem; /* Padding for cells */\n  text-align: left;\n}\n\n.bg-blue-100 {\n  background-color: #dbeafe;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
