import { poster } from "../calls";

export async function inviteUserByEmail(email) {
  try {
    return await poster(
      {
        email: email,
      },
      "user/invite_user"
    );
  } catch (error) {
    console.error(error);
  }
}

export async function deleteUser(user_id) {
  try {
    return await poster(
      {
        target_user_id: user_id,
      },
      "organization/remove_users"
    );
  } catch (error) {
    console.error(error);
  }
}
/** TODO: do we need to be sending the role with this route **/
export async function editUser(user_id, role) {
  try {
    return await poster(
      {
        target_user_id: user_id,
        role: role,
      },
      "organization/modify_users"
    );
  } catch (error) {
    console.error(error);
  }
}
/** TODO: how does this function know which org of user to get **/
export async function getUsers() {
  try {
    return await poster({}, "organization/fetch_users");
  } catch (error) {
    console.error(error);
  }
}
