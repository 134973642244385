import { Navigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { poster } from "../../calls";
import useToggle from "../../hooks/useToggle";
import { saveAs } from "file-saver";
import EXIF from "exif-js";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { find_next_feature } from "../../services/tile_service";
import { fetchTripByType } from "../../services/tripService";
import { imageryType } from "../../components/constants";
import { getVehiclesByTripID } from "../../services/vehicleService";
import { getCameraByVehicleId } from "../../services/cameraService";
import {
  deleteImage,
  getImage,
  getImagesBySequenceId,
} from "../../services/imageService";
import {
  deleteSequence,
  getSequencesByCameraId,
  getSequencesByTripId,
} from "../../services/sequenceService";
import { getDaysByVehicleId } from "../../services/dayService";
// CONTEXT IMPORTS //
export const DataContext = createContext({});
export const DataProvider = ({ children }) => {
  const { setUser } = useContext(AuthContext);
  //BOOLEAN TOGGLES
  const [LayerMenuOpen, toggleLayerMenuOpen] = useToggle(false);
  const [sidebarOpen, toggleSidebar] = useToggle(true);
  const [isLoading, toggleIsLoading] = useToggle(false);
  const [loadingTimeout, setLoadingTimeout] = useState(0);
  const [filterMenuOpen, toggleFilterMenuOpen] = useToggle(false);
  const [followInJosm, toggleFollowInJosm] = useToggle(false);
  const [toolMenuOpen, toggleToolMenuOpen] = useToggle(false);
  const [minimapOpen, setMinimapOpen] = useToggle(true);
  const [isMapSwap, setisMapSwap] = useToggle(false);
  const [osm, setOsm] = useToggle(false);
  const [license, setLicense] = useToggle(false);
  const [autoPlay, setAutoPlay] = useToggle(false);
  const [newtripSelected, setNewtripSelected] = useToggle(false);
  const [searchOpen, toggleSearchOpen] = useToggle(false);
  const [addImagesOpen, toggleAddImagesOpen] = useToggle(false);
  const [heartbeat] = useToggle(false);

  //OBJECT STATES
  const [imageDetails, setImageDetails] = useState({});
  const [tempImageGeoJson, setTempImageGeoJson] = useState({
    type: "FeatureCollection",
    features: [],
  });
  //NUMERIC STATES
  const [rotateOffset, setRotateOffset] = useState(0);
  const [currentYaw, setCurrentYaw] = useState(0);
  const [imageBrightness, setImageBrightness] = useState(100);
  const [currentFeatureCollectionIndex, setcurrentFeatureCollectionIndex] =
    useState(0);
  const [currentImgCount, setCurrentImgCount] = useState(0);
  const [totalImgCount, setTotalImgCount] = useState(100);
  const [zoom, setZoom] = useState(1);
  //STRING STATES
  const [uploadMethod, setUploadMethod] = useState("");
  const [connectingText, setConnectingText] = useState("Not Connected");
  //ARRAY STATES
  const [tempImageUrls, setTempImageUrls] = useState([]);
  const [selectedTripDays, setSelectedTripDays] = useState([]);
  const [sequenceImages, setSequenceImages] = useState([]);
  const [daySequences, setDaySequences] = useState([]);
  const [vehicleCameras, setVehicleCameras] = useState([]);
  const [vehicleDays, setVehicleDays] = useState([]);
  const [vectorSourceIds, setVectorSourceIds] = useState([]);

  const [projectDirectionSequences, setProjectDirectionSequences] = useState(
    []
  );
  const [filterParameters, setFilterParameters] = useState([]);
  const [tripVehiclesFilters, setTripVehiclesFilters] = useState([]);
  const [tripCamerasFilters, setTripCamerasFilters] = useState([]);
  const [tripDaysFilters, setTripDaysFilters] = useState([]);
  const [tripVehicles, setTripVehicles] = useState([]);
  const [tempImages, setTempImages] = useState([]);
  //NULL STATES BY DEFAULT
  const [selectedPicture, setSelectedPicture] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [sequenceSelected, setSequenceSelected] = useState(null);
  const [lastZoomedPicture, setLastZoomedPicture] = useState(null);

  const [selectedTripCloudPath, setSelectedTripCloudPath] = useState(null);
  const [selectedVehicleName, setSelectedVehicleName] = useState(null);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [selectedSequenceIndex, setSelectedSequenceIndex] = useState(null);
  const [selectedSequenceId, setSelectedSequenceId] = useState(null);
  const [selectedSequenceLength, setSelectedSequenceLength] = useState(null);
  const [dayOfTravel, setDayOfTravel] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [tripSelected, setTripSelected] = useState(null);
  const [GPXfile, setGPXfile] = useState(null);
  const [regionSelected, setRegionSelected] = useState(null);
  const [aws_access_key, setAccessToken] = useState(null);
  const [aws_secret_key, setSecretKey] = useState(null);
  const [path, setBasePath] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const [userSelectedName, setUserSelectedName] = useState(null);
  const [selectedView, setSelectedView] = useState(null);
  const [selectedPictureDirection, setSelectedPictureDirection] =
    useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [projectSelected, setProjectSelected] = useState(null);
  const [drawMode, setDrawMode] = useState(false);
  const [selectionType, setSelectionType] = useState("Image");
  const [sequenceFilename, setSequenceFilename] = useState(null);
  const [sequenceDate, setSequenceDate] = useState(null);

  //REFS
  const mapRef = useRef();
  const pannellumRef = useRef();
  const prismaZoom = useRef();
  const [mapUrl, setMapUrl] = useState("");

  const [currentFeature, setCurrentFeature] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState("2024-01-01");
  const [filterEndDate, setFilterEndDate] = useState("2026-01-24");
  const [unfilteredViews, setUnfilteredViews] = useState([
    "Front",
    "Left",
    "Right",
    "360",
  ]);

  const imageryType = {
    SAT: "mapbox://styles/mapbox/satellite-v8",
    LIGHT: "mapbox://styles/mapbox/light-v8",
    DARK: "mapbox://styles/mapbox/dark-v8",
    STREETS: "mapbox://styles/mapbox/streets-v12",
    SATSTREETS: "mapbox://styles/mapbox/satellite-streets-v12",
    OUTDOORS: "mapbox://styles/mapbox/outdoors-v11",
  };

  const [currentImagery, setCurrentImagery] = useState(imageryType.STREETS);
  const [trips, setTrips] = useState([]);

  const [showMarker, setShowMarker] = useState(false);
  const [markerLng, setMarkerLng] = useState(0);
  const [markerLat, setMarkerLat] = useState(0);

  //USE EFFECTS
  useEffect(() => {
    if (isLoading) {
      setLoadingTimeout((prevCount) => prevCount + 1);

      if (loadingTimeout > 10) {
        toggleIsLoading(false);
        alert("Cannot find selected sequence or image.");
      }
    }
    // eslint-disable-next-line
  }, [heartbeat]);

  //HANDLERS
  const handleIsMapSwap = () => {
    setisMapSwap(!isMapSwap);
  };

  const handleSetMiniMapState = () => {
    setMinimapOpen(!minimapOpen);
  };

  const handleSetFacing = (e) => {
    setSelectedView(e);
  };

  const handleSetTripSelected = (e) => {
    setTripSelected(e);
  };

  const handleSetSidebarState = () => {
    toggleSidebar();
    setTimeout(() => {
      if (mapRef && mapRef.current) {
        mapRef.current.getMap().resize();
      }
    }, 1);
  };

  const handle_License_change = (e) => {
    setLicense(e.target.value);
  };

  const handleSetTempImages = async (e, imagetype = null, facing = null) => {
    setTempImageUrls([]);
    setTotalImgCount(0);
    setTempImageGeoJson(null);
    let temp_geoJson = {
      type: "FeatureCollection",
      features: [],
    };
    let lineFeature = {
      type: "Feature",
      properties: {
        id: 0,
        index: 0,
        image_facing: facing,
      },
      geometry: {
        type: "LineString",
        coordinates: [],
      },
    };
    var selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        setTotalImgCount(e.target.files.length);
        const file = selectedFiles[i];
        const exifInfo = await extractExif(file);
        lineFeature.geometry.coordinates.push([exifInfo.Longi, exifInfo.Lati]);
        let feature = {
          type: "Feature",
          properties: {
            id: i,
            direction: exifInfo.heading,
            timestamp: exifInfo.timestamp,
            index: i,
            facing: facing,
            type: "temp",
          },
          geometry: {
            type: "Point",
            coordinates: [exifInfo.Longi, exifInfo.Lati],
          },
        };
        if (imagetype !== "drive") {
          feature.properties.facing = "";
        }
        temp_geoJson.features.push(feature);
      }
      if (imagetype === "drive") {
        temp_geoJson.features.push(lineFeature);
      }
      setTempImageGeoJson(temp_geoJson);
      if (mapRef && mapRef.current) {
        mapRef.current.getMap().flyTo({
          center: [
            temp_geoJson.features[0].geometry.coordinates[0],
            temp_geoJson.features[0].geometry.coordinates[1],
          ],
          duration: 2000,
          zoom: 18,
        });
      }
      let urls = await extractImgUrl(selectedFiles);
      for (let i = 0; i <= urls.length - 1; i++) {
        temp_geoJson.features[i].properties["img_url"] = urls[i];
      }
      setTempImageUrls(urls);
      let tempJson = updateGeoJsonWithUrls(temp_geoJson, urls, imagetype);
      setTempImageGeoJson(tempJson);
    }
  };

  const updateGeoJsonWithUrls = (geojson, urls) => {
    let updatedGeoJson = { ...geojson };
    if (urls.length > 0) {
      let lastIndex;
      lastIndex = urls.length - 1;
      for (let i = 0; i <= lastIndex; i++) {
        updatedGeoJson.features[i].properties.img_url = urls[i];
      }
    }
    return updatedGeoJson;
  };

  const extractImgUrl = async (files) => {
    const filesArray = Array.from(files);
    return await Promise.all(
      filesArray.map(async (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            let previewUrl = e.target.result;
            resolve(previewUrl);
            setCurrentImgCount((prevCount) => prevCount + 1);
          };

          reader.readAsDataURL(file);
        });
      })
    );
  };

  const extractExif = async (file) => {
    return new Promise((resolve, reject) => {
      EXIF.getData(file, function () {
        var exifData = EXIF.pretty(this);
        if (exifData) {
          let lat = EXIF.getTag(this, "GPSLatitude");
          let latRef = EXIF.getTag(this, "GPSLatitudeRef");
          let Lati = ConvertDMSToDD(lat[0], lat[1], lat[2], latRef);
          let lon = EXIF.getTag(this, "GPSLongitude");
          let lonRef = EXIF.getTag(this, "GPSLongitudeRef");
          let Longi = ConvertDMSToDD(lon[0], lon[1], lon[2], lonRef);
          let timestamp = EXIF.getTag(this, "DateTimeOriginal");
          let heading = EXIF.getTag(this, "GPSImgDirection");
          heading = heading.numerator / heading.denominator;
          resolve({ Lati, Longi, heading, timestamp });
        } else {
          reject(new Error("No EXIF data found in image '" + file.name + "'."));
        }
      });
    });
  };

  function ConvertDMSToDD(degrees, minutes, seconds, direction) {
    let dd = degrees + minutes / 60 + seconds / (60 * 60);
    if (direction === "S" || direction === "W") {
      dd = dd * -1;
    }
    return dd;
  }

  // RESET ORG UPLOAD BOOKMARKS //
  const clear_upload_bookmark = () => {
    let clearBookmarkURL = "upload/clear_upload_bookmark";
    let outpack = {
      trip_id: tripSelected,
    };
    poster(outpack, clearBookmarkURL).then((response) => {
      if (response.status === 200) {
        alert(response.message);
      } else if (response.status === 304) {
        setUser(null);
        return <Navigate push to={"/login"} />;
      } else if (!response.ok) {
        if (response.status === 524) {
          alert("RequestTimeout: Please try again later.");
        } else {
          console.log(`Error:`, response);
        }
      }
    });
  };

  // FETCH TRIP VEHICLES //
  const fetchTripVehicles = async (trip_id) => {
    const response = await getVehiclesByTripID(trip_id);
    if (response.status === 200) {
      setTripVehicles(response.vehicles);
    } else {
      console.error(response);
    }
  };

  // FETCH VEHICLE DAYS //
  const fetchVehicleDays = async (vehicle_id) => {
    const response = await getDaysByVehicleId(vehicle_id);
    if (response.status === 200) {
      setVehicleDays(response.days);
    } else {
      console.error(response);
    }
  };

  // FETCH VEHICLE CAMERAS //
  const fetchVehicleCameras = async (vehicle_id, day_id) => {
    const response = await getCameraByVehicleId(vehicle_id, day_id);
    if (response.status === 200) {
      setVehicleCameras(response.cameras);
    } else {
      console.error(response);
    }
  };

  const fetch_day_sequences = async (day_id, camera_id) => {
    const response = await getSequencesByCameraId(day_id, camera_id);
    if (response.status === 200) {
      setDaySequences(response.sequences);
    } else {
      console.error(response);
    }
  };

  const fetch_sequence_images = async (sequence_id) => {
    const response = await getImagesBySequenceId(sequence_id);
    if (response.status === 200) {
      setSequenceImages(response.images);
    } else {
      console.error(response);
    }
  };

  // FETCH ORGANIZATION PROJECTS //
  const handleFetchOrgTrips = async (upload_complete) => {
    const response = await fetchTripByType(upload_complete);
    setTrips(response.data);
  };

  const handle_change_imagery = (e) => {
    setCurrentImagery(e);
  };

  // FETCH PROJECT SEQUENCES //
  const fetchProjectDirectionSequences = async (project_id, direction) => {
    const response = await getSequencesByTripId(project_id, direction);
    if (response.status === 200) {
      setProjectDirectionSequences(response.sequences);
    } else {
      console.error(response);
    }
  };

  // DELETE SEQUENCE //
  const wrapDeleteSequence = async (delete_originals = false) => {
    const response = await deleteSequence(delete_originals, selectedSequence);
    if (response.status === 200) {
      alert("Sequence Deleted");
    } else {
      console.error(response);
    }
  };

  // FETCH IMAGE NOTES //
  const delete_image = async (image_id) => {
    const response = await deleteImage(image_id);
    if (response.status === 200) {
      alert(response.message);
    } else {
      console.error(response);
    }
  };

  const fetch_image_details = async () => {
    const response = await getImage(selectedImage);
    if (response.status === 200) {
      setImageDetails(response.image_details);
    } else {
      console.error(response);
    }
  };

  const exportGPX = (selection, facing = null, sequence_id = null) => {
    let downloadGPXURL = `api/project/export_GPX?selection=${selection}&facing=${facing}&sequence_id=${sequence_id}&project_id=${tripSelected}`;
    if (sequence_id === null) {
      sequence_id = "ALL";
    }
    if (facing === null) {
      facing = selectedView;
    }
    let fileName = `Project-${tripSelected}-Sequence-${sequence_id}-Facing-${facing}.gpx`;
    toggleIsLoading(true);
    return fetch(downloadGPXURL, { method: "GET" })
      .then((response) => response.blob())
      .then((blob) => saveAs(blob, fileName))
      .then(toggleIsLoading(false));
  };

  const makePicture = (properties, geometry) => {
    return {
      ...geometry,
      ...properties,
      ...{ timestamp: new Date(properties.timestamp) },
    };
  };

  const [isCalling, setIsCalling] = useState(false);
  function handle_cycle_feature(type) {
    if (!isCalling) {
      setIsCalling(true);
      cycle_feature(type)
        .then(() => {
          setIsCalling(false);
        })
        .catch((error) => {
          console.error("Error cycling feature:", error);
          setIsCalling(false);
        });
    }
  }

  const cycle_feature = async (type) => {
    if (!currentFeature) {
      return;
    }
    let lng = currentFeature.geometry.coordinates[0];
    let lat = currentFeature.geometry.coordinates[1];
    let timestamp = currentFeature.properties.timestamp;
    let direction = currentFeature.properties.facing;
    // Find potential nodes
    let feature = await find_next_feature(
      lat,
      lng,
      50,
      type,
      timestamp,
      direction,
      2,
      mapUrl
    );

    if (feature !== undefined) {
      await select_feature(feature);
    } else {
      setIsPlaying(false);
      alert("No next feature found");
    }
  };

  const handleSetSelectedView = async (direction) => {
    if (currentFeature) {
      let lng = currentFeature.geometry.coordinates[0];
      let lat = currentFeature.geometry.coordinates[1];
      let timestamp = currentFeature.properties.timestamp;
      let feature = await find_next_feature(
        lat,
        lng,
        50,
        "equal",
        timestamp,
        direction,
        2,
        mapUrl
      );

      if (feature !== undefined) {
        select_feature(feature);
      } else {
        alert("No feature found");
      }
    }
  };

  function select_feature(feature) {
    let pic = {};
    try {
      pic = {
        img_url: feature.properties.img,
        coordinates: feature.geometry.coordinates,
        facing: feature.properties.facing,
        direction: feature.properties.heading,
      };
    } catch (error) {
      console.error("Error Selecting Feature", error);
      console.log(feature);
      return;
    }
    setCurrentFeature(feature);
    setSelectedView(feature.properties.facing);
    setSelectedPicture(pic);
    try {
      if (mapRef && mapRef.current) {
        mapRef.current.getMap().easeTo({
          center: feature.geometry.coordinates,
          duration: 0,
          zoom: 18,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  const [isPlaying, setIsPlaying] = useToggle(false);
  const [autoplayTimer, setAutoplayTimer] = useState(2000);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isPlaying) {
        handle_cycle_feature("up");
      }
    }, autoplayTimer);

    return () => clearInterval(intervalId);
  }, [isPlaying, currentFeature]);

  // HANDLES TOGGLING AUTOPLAY ON/OFF //
  const handle_autoplay = () => {
    setIsPlaying();
  };

  const handleSetAutoplayTimer = (e) => {
    setAutoplayTimer(e.target.value);
  };

  //MOSTLY NON BLOCKING SLEEP TIMER FOR AUTOPLAY FEATURE
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  //JOSM COMMAND HANDLER
  const josmPictureCommand = async (command, picture) => {
    if (picture) {
      try {
        let josmRemoteControl = "http://127.0.0.1:8111";
        await fetch(josmRemoteControl + "/version")
          .then((response) => response.json())
          .then((json) => json.version)
          .then((version) => sendJosmCommand(version, picture, command));
      } catch (e) {
        console.log(e);
      }
    }
  };

  // SEND VIEW SELECTED FEATURE IN JOSM REMOTE CONTROL COMMAND
  async function sendJosmCommand(version, selectedPicture, zoomCommand) {
    let josmRemoteControl = "http://127.0.0.1:8111";
    const buffer = 0.0007;
    let coords = selectedPicture.coordinates;
    let zoomProps = {
      left: coords[0] - buffer,
      right: coords[0] + buffer,
      bottom: coords[1] - buffer,
      top: coords[1] + buffer,
    };
    const zoomUrl = new URL(josmRemoteControl + zoomCommand);
    Object.keys(zoomProps).forEach((key) =>
      zoomUrl.searchParams.append(key, zoomProps[key])
    );
    await fetch(zoomUrl);
    if (version > 17534) {
      const loadCommand = "/open_file";
      const loadPictureUrl = new URL(josmRemoteControl + loadCommand);
      let loadPictureParams = { filename: selectedPicture.img_url };
      Object.keys(loadPictureParams).forEach((key) =>
        loadPictureUrl.searchParams.append(key, loadPictureParams[key])
      );
      await fetch(loadPictureUrl);
    }
  }

  const update_url = (feature) => {
    if (window.location.hash.includes("&key=")) {
      window.location.hash = window.location.hash.replace(
        /key=.*/,
        "key=" + JSON.stringify(feature)
      );
    } else {
      window.location.hash += "&key=" + JSON.stringify(feature);
    }
  };

  const handleToggleMapMenus = () => {
    toggleLayerMenuOpen(false);
    toggleFilterMenuOpen(false);
    toggleSearchOpen(false);
    toggleAddImagesOpen(false);
    toggleToolMenuOpen(false);
  };

  //EXPORTS
  const value = {
    //REFS
    mapRef,
    pannellumRef,
    //DATA
    prismaZoom,
    //TOGGLE STATES
    sidebarOpen,
    toggleSidebar,
    followInJosm,
    toggleFollowInJosm,
    isLoading,
    toggleIsLoading,
    LayerMenuOpen,
    toggleLayerMenuOpen,
    filterMenuOpen,
    toggleFilterMenuOpen,
    toolMenuOpen,
    toggleToolMenuOpen,
    searchOpen,
    toggleSearchOpen,
    addImagesOpen,
    toggleAddImagesOpen,
    //STATES AND SETTERS
    data: rawData,
    setData: setRawData,
    osm,
    setOsm,
    selectedPicture,
    setSelectedPicture,
    license,
    setLicense,
    regionSelected,
    setRegionSelected,
    aws_access_key,
    setAccessToken,
    aws_secret_key,
    setSecretKey,
    path,
    setBasePath,
    projectDirectionSequences,
    setProjectDirectionSequences,
    userSelected,
    setUserSelected,
    userSelectedName,
    setUserSelectedName,
    uploadMethod,
    setUploadMethod,
    selectedView,
    setSelectedView,
    currentFeatureCollectionIndex,
    setcurrentFeatureCollectionIndex,
    tripSelected,
    setTripSelected,
    selectedSequenceLength,
    setSelectedSequenceLength,
    autoPlay,
    setAutoPlay,
    selectedPictureDirection,
    setSelectedPictureDirection,
    selectedSequence,
    setSelectedSequence,
    selectedSequenceId,
    setSelectedSequenceId,
    selectedSequenceIndex,
    setSelectedSequenceIndex,
    lastZoomedPicture,
    setLastZoomedPicture,
    sequenceSelected,
    setSequenceSelected,
    projectSelected,
    setProjectSelected,
    rotateOffset,
    setRotateOffset,
    currentYaw,
    setCurrentYaw,
    zoom,
    setZoom,
    newtripSelected,
    setNewtripSelected,
    vectorSourceIds,
    setVectorSourceIds,
    imageBrightness,
    setImageBrightness,
    GPXfile,
    setGPXfile,
    minimapOpen,
    setMinimapOpen,
    selectedTripDays,
    setSelectedTripDays,
    isMapSwap,
    setisMapSwap,
    connectingText,
    setConnectingText,
    tripVehicles,
    setTripVehicles,
    vehicleDays,
    setVehicleDays,
    selectedVehicle,
    setSelectedVehicle,
    vehicleCameras,
    setVehicleCameras,
    dayOfTravel,
    setDayOfTravel,
    selectedVehicleName,
    setSelectedVehicleName,
    selectedTripCloudPath,
    setSelectedTripCloudPath,
    daySequences,
    setDaySequences,
    sequenceImages,
    setSequenceImages,
    imageDetails,
    setImageDetails,
    selectedImage,
    setSelectedImage,
    tripVehiclesFilters,
    setTripVehiclesFilters,
    tripCamerasFilters,
    setTripCamerasFilters,
    tripDaysFilters,
    setTripDaysFilters,
    filterParameters,
    setFilterParameters,
    drawMode,
    setDrawMode,
    tempImages,
    setTempImages,
    tempImageGeoJson,
    setTempImageGeoJson,
    tempImageUrls,
    setTempImageUrls,
    //HANDLERS
    handleSetMiniMapState,
    handleIsMapSwap,
    handleSetSidebarState,
    handle_License_change,
    handleSetFacing,
    handleSetTripSelected,
    handleSetTempImages,
    handleToggleMapMenus,
    //FETCHERS
    fetchProjectDirectionSequences,
    handleFetchOrgTrips,
    fetchTripVehicles,
    fetchVehicleDays,
    fetchVehicleCameras,
    fetch_day_sequences,
    fetch_sequence_images,
    fetch_image_details,
    // OTHER API CALL FUNCTIONS
    deleteSequence: wrapDeleteSequence,
    makePicture,
    exportGPX,
    delete_image,
    // OTHER FUNCTIONS
    josmPictureCommand,
    sleep,
    clear_upload_bookmark,
    currentImgCount,
    totalImgCount,
    selectionType,
    setSelectionType,
    sequenceFilename,
    setSequenceFilename,
    sequenceDate,
    setSequenceDate,
    handle_autoplay,
    handleSetAutoplayTimer,
    isPlaying,
    currentFeature,
    handleSetSelectedView,
    filterStartDate,
    setFilterStartDate,
    filterEndDate,
    setFilterEndDate,
    update_url,
    setUnfilteredViews,
    unfilteredViews,
    mapUrl,
    setMapUrl,
    select_feature,
    handle_cycle_feature,
    setCurrentImagery,
    currentImagery,
    handle_change_imagery,
    setTrips,
    trips,
    imageryType,
    showMarker,
    setShowMarker,
    markerLng,
    setMarkerLng,
    markerLat,
    setMarkerLat,
  };

  return value ? (
    <DataContext.Provider value={value}>{children}</DataContext.Provider>
  ) : null;
};
