import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../Contexts/DataContext";
import { AuthContext } from "../../Contexts/AuthContext";
import useToggle from "../../hooks/useToggle.js";
import { Navigate } from "react-router-dom";
import { CardMediaStyle, TableCard } from "../../components/base/styles";

import { SelectionRow } from "../../components/features/uploadComponents/selectionRow";
import "./styles.css";
import {
  CameraModelSelectionComponent,
  CloudDestinationSelectionComponent,
  CloudFileSelectionComponent,
  CloudServiceSelection,
  CloudUploadSelection,
  ConnectToCloudComponent,
  ProceedWithUploadComponent,
  SelectCloudDestinationModal,
  SelectCloudFolderModal,
  SelectUploadDestinationModal,
  UploadDestinationComponent,
  UploadSequenceTypeSelectionComponent,
  UploadSourceSelectionComponent,
} from "../../components/features/uploadComponents/uploadComponents";

import { AddTripModal } from "../../components/features/tripModals/addTripModal";
import ViewerUploaderLandingPage from "../../images/ViewerUploaderLandingPage.png";
import {
  checkS3Connection,
  listS3Objects,
  processCloudFiles,
} from "../../services/cloudService";

export const AdminUploadPage = () => {
  const {
    connectingText,
    setConnectingText,
    sleep,
    tripVehicles,
    setTripVehicles,
    setSelectedVehicleName,
    setDayOfTravel,
    selectedTripCloudPath,
    setSelectedTripCloudPath,
    isLoading,
    toggleIsLoading,
    tripSelected,
    setTripSelected,
  } = useContext(DataContext);

  const { user } = useContext(AuthContext);
  const [cloudConnected, toggleCloudConnected] = useToggle(false);
  const [backButton, toggleBackButton] = useToggle(false);
  const [filesSelected, toggleFilesSelected] = useToggle(false);
  const [destinationSelected, toggleDestinationSelected] = useToggle(false);
  const [uploadDestinationOpen, toggleUploadDestinationOpen] = useToggle(false);
  const [cloudDestinationOpen, toggleCloudDestinationOpen] = useToggle(false);
  const [selectCloudFoldersOpen, toggleSelectCloudFoldersOpen] =
    useToggle(false);
  const [uploadSource, setUploadSource] = useState(null);
  const [cloudService, setCloudService] = useState(null);
  const [cloudPath, setCloudPath] = useState(null);
  const [region, setRegion] = useState(null);
  const [bucket, setBucket] = useState(null);
  const [token, setToken] = useState(null);
  const [key, setKey] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [cameraModel, setCameraModel] = useState(null);
  const [tripSelectedName, setTripSelectedName] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [processSubfolders, toggleProcessSubfolders] = useToggle(false);
  const [selectionPage, setSelectionPage] = useState(1);
  const [tripType, setTripType] = useState(null);
  const [sequenceType, setSequenceType] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [cloudFolderContents, setCloudFolderContents] = useState([]);
  const [cloudFileContents, setCloudFileContents] = useState([]);

  // const fileInputRef = useRef(null);
  const [localImages, setLocalImages] = useState([]);
  // const [confirm, toggleConfirm] = useToggle();

  //Creating new trip states
  // const [vehicleIndex, setVehicleIndex] = useState(0);
  // const [vehicles, setVehicles] = useState([]);
  const [page, setPage] = useState(1);
  const [addOpen, toggleAddOpen] = useToggle(false);

  useEffect(() => {
    if (user === null || user.role !== "admin") {
      setRedirect(true);
    }
  }, []);

  const handleNullStates = () => {
    setBucket(null);
    setKey(null);
    setToken(null);
    setRegion(null);
    setMediaType(null);
    setUploadSource(null);
    setCloudService(null);
    setSelectedFiles(null);
    setCameraModel(null);
    toggleCloudConnected(false);
    toggleDestinationSelected(false);
    toggleFilesSelected(false);
    setSelectedVehicleName(null);
    setDayOfTravel(null);
    setTripType(null);
  };

  const handleCloudDestinationOpen = () => {
    if (tripType === "new") {
      handleNewUploadDestinationOpen();
    } else {
      toggleCloudDestinationOpen();
      setSelectionPage(1);
    }
  };

  const handleNewUploadDestinationOpen = () => {
    setPage(1);
    toggleAddOpen();
  };

  const handleUploadDestinationOpen = () => {
    toggleUploadDestinationOpen();
    setSelectionPage(1);
  };

  const handleSelectCloudFoldersOpen = () => {
    toggleSelectCloudFoldersOpen();
  };

  const handleSetUploadSource = (e) => {
    setUploadSource(e.target.value);
  };

  const handleSetMediaType = (e) => {
    if (e.target.value === "images") {
      setCameraModel(e.target.value);
    }
    setMediaType(e.target.value);
  };

  const handleToggleProcessSubfolders = () => {
    toggleProcessSubfolders();
  };

  const handleSetTripVehicles = (vehicles) => {
    setTripVehicles(vehicles);
  };

  const handleSetCameraModel = (e) => {
    setCameraModel(e.target.value);
  };

  const handleSetConnectingText = (text) => {
    setConnectingText(text);
  };

  const handleCheckDigitaloceanConnection = async () => {
    // if (bucket && key && token && region) {
    setSelectedFiles([]);
    handleSetConnectingText("Attempting connection...");
    await sleep(2500);
    checkCloudConnection(cloudService, mediaType);
    setBucket(null);
    setToken(null);
    setKey(null);
    setRegion(null);
    // }
  };

  const checkCloudConnection = async (service, type) => {
    const response = await checkS3Connection(service, type);
    if (response.status === 200) {
      if (response.connection_status === true) {
        setConnectingText(`Connected to Bucket`);
        setCloudFolderContents(response.contents.folders);
        setCloudFileContents(response.contents.files);
        toggleCloudConnected(true);
      } else {
        setConnectingText(`Connection to Bucket Failed`);
        toggleCloudConnected(false);
      }
    } else {
      console.error(response);
    }
  };

  const listCloudFilesAndFolders = async (path, media_type) => {
    const response = await listS3Objects(path, media_type);
    if (response.status === 200) {
      setCloudFolderContents(response.folders);
      setCloudFileContents(response.files);
    } else {
      console.error(response);
    }
  };

  const handleSetSelectedVehicle = (e) => {
    setSelectedVehicle(e.target.value);
  };

  const handleSetTripType = (e) => {
    setTripType(e.target.value);
  };

  const handleSetCloudPath = (folder) => {
    let tempPath = cloudPath;
    if (tempPath === null || tempPath === "/") {
      tempPath = folder;
      setCloudPath(folder);
    } else {
      tempPath = tempPath + folder;
      setCloudPath(tempPath);
    }
    toggleBackButton(true);
    listCloudFilesAndFolders(tempPath, mediaType);
  };

  const handleSetCloudPathBack = (folder) => {
    let tempPath = cloudPath;
    if (tempPath.endsWith("/")) {
      tempPath = tempPath.slice(0, -1);
    }
    tempPath = tempPath.split("/");
    tempPath.pop();
    if (tempPath.length < 1) {
      toggleBackButton(false);
    }
    tempPath = tempPath.join("/") + "/";
    setCloudPath(tempPath);
    listCloudFilesAndFolders(tempPath, mediaType);
  };

  const handleSetSelectedFiles = (e) => {
    let index = selectedFiles.indexOf(e.target.value);
    if (index !== -1) {
      setSelectedFiles(
        selectedFiles.filter((i) => selectedFiles.indexOf(i) !== index)
      );
    } else {
      setSelectedFiles([...selectedFiles, e.target.value]);
    }
  };

  const handleSetAllFilesSelected = () => {
    if (selectedFiles.length === 0) {
      setSelectedFiles(cloudFileContents);
    } else {
      setSelectedFiles([]);
    }
  };

  const handleSetCloudService = (e) => {
    setCloudService(e.target.value);
  };

  const handleTripSelected = (id, name) => {
    setTripSelected(id);
    setTripSelectedName(name);
  };

  const handleSetSelectionPage = (page) => {
    setSelectionPage(page);
  };

  const handlePageBack = () => {
    if (selectionPage === 1) {
      toggleUploadDestinationOpen();
      setSelectionPage(null);
    }
    if (selectionPage === 2) {
      setSelectionPage(1);
    }
    if (selectionPage === 3) {
      setSelectionPage(2);
    }
    if (selectionPage === 4) {
      setSelectionPage(3);
    }
    if (selectionPage === 5) {
      setSelectionPage(4);
    }
  };

  const handleSelectFiles = () => {
    toggleSelectCloudFoldersOpen();
    toggleFilesSelected(true);
  };

  const handleSetUploadDestination = () => {
    toggleUploadDestinationOpen(false);
    toggleDestinationSelected(true);
  };

  const handleChangeDestinationSelected = () => {
    toggleDestinationSelected(false);
    handleUploadDestinationOpen();
  };

  const handleSetSequenceType = (e) => {
    setSequenceType(e.target.value);
  };

  const handleCancelUpload = () => {
    handleNullStates();
    setSelectionPage(1);
  };

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedCameraFacing, setSelectedCameraFacing] = useState(null);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const handleProcessCloudFiles = async () => {
    toggleIsLoading(true);
    await processCloudFiles(
      cloudPath,
      selectedFiles,
      tripSelected,
      processSubfolders,
      selectedFolder,
      mediaType,
      selectedDay,
      selectedCameraFacing,
      selectedCamera,
      selectedVehicle
    );
  };

  const handleSetSelectedFolder = (folder) => {
    setSelectedFolder(folder);
  };

  const handleSetLocalImages = (e) => {
    let selectedFiles = e.target.files;
    setLocalImages(selectedFiles);
  };

  return (
    <>
      <AddTripModal
        addOpen={addOpen}
        toggleAddOpen={handleNewUploadDestinationOpen}
        handleTripSelected={handleTripSelected}
        toggleDestinationSelected={toggleDestinationSelected}
      />

      <SelectUploadDestinationModal
        processSubfolders={processSubfolders}
        selectedFolder={selectedFolder}
        handleUploadDestinationOpen={handleUploadDestinationOpen}
        uploadDestinationOpen={uploadDestinationOpen}
        tripSelected={tripSelected}
        handleTripSelected={handleTripSelected}
        selectionPage={selectionPage}
        handleSetSelectionPage={handleSetSelectionPage}
        handlePageBack={handlePageBack}
        handleSetTripType={handleSetTripType}
        selectedVehicle={selectedVehicle}
        handleSetSelectedVehicle={handleSetSelectedVehicle}
        tripVehicles={tripVehicles}
        handleSetTripVehicles={handleSetTripVehicles}
        tripSelectedName={tripSelectedName}
        handleSetUploadDestination={handleSetUploadDestination}
        selectedTripCloudPath={selectedTripCloudPath}
        setSelectedTripCloudPath={setSelectedTripCloudPath}
      />
      <SelectCloudDestinationModal
        processSubfolders={processSubfolders}
        selectedFolder={selectedFolder}
        handleCloudDestinationOpen={handleCloudDestinationOpen}
        cloudDestinationOpen={cloudDestinationOpen}
        tripSelected={tripSelected}
        cloudConnected={cloudConnected}
        handleTripSelected={handleTripSelected}
        uploadSource={uploadSource}
        selectionPage={selectionPage}
        cloudFolderContents={cloudFolderContents}
        cloudFileContents={cloudFileContents}
        handleSetSelectionPage={handleSetSelectionPage}
        handlePageBack={handlePageBack}
        handleSetTripType={handleSetTripType}
        selectedVehicle={selectedVehicle}
        handleSetSelectedVehicle={handleSetSelectedVehicle}
        tripVehicles={tripVehicles}
        handleSetTripVehicles={handleSetTripVehicles}
        tripSelectedName={tripSelectedName}
        handleSetUploadDestination={handleSetUploadDestination}
        selectedTripCloudPath={selectedTripCloudPath}
        setSelectedTripCloudPath={setSelectedTripCloudPath}
        handleSetSelectedFiles={handleSetSelectedFiles}
        handleSetAllFilesSelected={handleSetAllFilesSelected}
        handleSetCloudPath={handleSetCloudPath}
        backButton={backButton}
        selectedFiles={selectedFiles}
        handleSetCloudPathBack={handleSetCloudPathBack}
        handleSelectFiles={handleSelectFiles}
        setSelectedFolder={setSelectedFolder}
        handleSetSelectedFolder={handleSetSelectedFolder}
        isLoading={isLoading}
      />
      <SelectCloudFolderModal
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        handleSetSelectedFolder={handleSetSelectedFolder}
        processSubfolders={processSubfolders}
        handleToggleProcessSubfolders={handleToggleProcessSubfolders}
        cloudConnected={cloudConnected}
        selectCloudFoldersOpen={selectCloudFoldersOpen}
        handleSelectCloudFoldersOpen={handleSelectCloudFoldersOpen}
        cloudFolderContents={cloudFolderContents}
        cloudFileContents={cloudFileContents}
        bucket={bucket}
        cloudPath={cloudPath}
        selectedFiles={selectedFiles}
        handleSetSelectedFiles={handleSetSelectedFiles}
        handleSetAllFilesSelected={handleSetAllFilesSelected}
        handleSetCloudPath={handleSetCloudPath}
        backButton={backButton}
        handleSetCloudPathBack={handleSetCloudPathBack}
        handleSelectFiles={handleSelectFiles}
        isLoading={isLoading}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "2vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1vh",
            marginTop: "5vh",
          }}
        >
          <h1>
            <strong>Desktop Uploader</strong>
          </h1>
          <h3 style={{ color: "#696969" }}>
            Upload large amounts of imagery to Viewer through our desktop app
          </h3>

          <h4>
            <strong>Download for:</strong>
          </h4>
          {/*<a*/}
          {/*  style={{ textDecoration: "none" }}*/}
          {/*  href={require("./Uploader.zip")}*/}
          {/*  download="Uploader"*/}
          {/*>*/}
          {/*  <StyledButton button_text={"macOS"} />*/}
          {/*</a>*/}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <img
            style={{ width: "500px", borderRadius: "5px" }}
            src={ViewerUploaderLandingPage}
            alt={"Viewer Uploader Landing Page"}
          />
        </div>
      </div>
      {/* <span style={{width:'50%'}}/> */}
      {/* <StyledButton
              button_text={"Process Day"}
              button_action={processDay}
            /> */}
      <h1>
        <strong>Web Uploader</strong>
      </h1>
      <TableCard>
        <CardMediaStyle />
        <SelectionRow
          title={"Media Type:"}
          valueState={mediaType}
          stateHandler={handleSetMediaType}
          inputs={[
            {
              type: "radio",
              title: "Video",
              value: "video",
            },
            {
              type: "radio",
              title: "Images",
              value: "images",
            },
            {
              type: "radio",
              title: "360 Video (Upcoming)",
              value: "360v",
              isDisabled: true,
            },
            {
              type: "radio",
              title: "360 Images (Upcoming)",
              value: "360i",
              isDisabled: true,
            },
          ]}
        />
        <CameraModelSelectionComponent
          mediaType={mediaType}
          cameraModel={cameraModel}
          handleSetCameraModel={handleSetCameraModel}
        />
        <UploadSourceSelectionComponent
          cameraModel={cameraModel}
          uploadSource={uploadSource}
          handleSetUploadSource={handleSetUploadSource}
        />
        <UploadSequenceTypeSelectionComponent
          handleSetSequenceType={handleSetSequenceType}
          sequenceType={sequenceType}
          uploadSource={uploadSource}
          // handleSetUploadSource={handleSetUploadSource}
        />
        <CloudServiceSelection
          localImages={localImages}
          handleSetLocalImages={handleSetLocalImages}
          uploadSource={uploadSource}
          sequenceType={sequenceType}
          processSubfolders={processSubfolders}
          selectedFolder={selectedFolder}
          handleSetUploadSource={handleSetUploadSource}
          cloudService={cloudService}
          handleSetCloudService={handleSetCloudService}
        />

        <CloudUploadSelection
          localImages={localImages}
          handleSetLocalImages={handleSetLocalImages}
          uploadSource={uploadSource}
          cloudService={cloudService}
          handleSetCloudService={handleSetCloudService}
        />

        <ConnectToCloudComponent
          localImages={localImages}
          uploadSource={uploadSource}
          cloudService={cloudService}
          cloudConnected={cloudConnected}
          connectingText={connectingText}
          // S3_regions={S3_regions}
          // handleSetBucket={handleSetBucket}
          // handleSetToken={handleSetToken}
          // handleSetKey={handleSetKey}
          // handleSetRegion={handleSetRegion}
          handleCheckDigitaloceanConnection={handleCheckDigitaloceanConnection}
        />

        <CloudDestinationSelectionComponent
          selectedFolder={selectedFolder}
          cloudConnected={cloudConnected}
          uploadSource={uploadSource}
          selectedFiles={selectedFiles}
          filesSelected={filesSelected}
          cloudDestinationOpen={cloudDestinationOpen}
          handleCloudDestinationOpen={handleCloudDestinationOpen}
        />
        <CloudFileSelectionComponent
          processSubfolders={processSubfolders}
          selectedFolder={selectedFolder}
          cloudConnected={cloudConnected}
          uploadSource={uploadSource}
          selectedFiles={selectedFiles}
          filesSelected={filesSelected}
          selectCloudFoldersOpen={selectCloudFoldersOpen}
          handleSelectCloudFoldersOpen={handleSelectCloudFoldersOpen}
        />
        <UploadDestinationComponent
          tripSelected={tripSelected}
          tripSelectedName={tripSelectedName}
          cloudConnected={cloudConnected}
          selectedFiles={selectedFiles}
          filesSelected={filesSelected}
          tripType={tripType}
          cloudPath={cloudPath}
          selectedTripCloudPath={selectedTripCloudPath}
          destinationSelected={destinationSelected}
          handleSetTripType={handleSetTripType}
          handleUploadDestinationOpen={handleUploadDestinationOpen}
          handleNewUploadDestinationOpen={handleNewUploadDestinationOpen}
          handleChangeDestinationSelected={handleChangeDestinationSelected}
        />
        <ProceedWithUploadComponent
          destinationSelected={destinationSelected}
          handleCancelUpload={handleCancelUpload}
          handleProcessCloudFiles={handleProcessCloudFiles}
        />
      </TableCard>
      {!redirect ? null : <Navigate push to="/login" />}
    </>
  );
};
