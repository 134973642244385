import { CancelConfirmButtons } from "../base/cancelConfirmButtons";
import { SectionSubtitle } from "../base/sectionSubtitle";
import { ModalTitle, ModalWrapper } from "../base/modalComponents";
import { Modal } from "@mui/material";

export const BaseModal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  confirmText,
  onConfirm,
  children,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      onClose();
    }
  };

  return (
    <Modal open={isOpen} onKeyDown={handleKeyDown}>
      <ModalWrapper>
        <ModalTitle title={title} closeButtonAction={onClose} />
        {subtitle && <SectionSubtitle subtitle_text={subtitle} />}
        {children}
        {confirmText && onConfirm && (
          <CancelConfirmButtons
            confirm_text={confirmText}
            confirm_action={onConfirm}
            cancel_text={"Cancel"}
            cancel_action={onClose}
          />
        )}
      </ModalWrapper>
    </Modal>
  );
};
