import React, { useState } from "react";
import { Navigate } from "react-router-dom";
//COMPONENT DECLARATION & EXPORT - SSO CONTROLS ON VIEWER LOGIN PAGE- SENDS INVITE INFO TO KAART SSO FOR NE USERS
export const SSOControl = () => {
  const [redirect] = useState(false);
  return (
    <>
      <button
        style={{
          display: "inline-block",
          position: "relative",
          backgroundColor: "transparent",
          cursor: "pointer",
          border: 0,
          paddingLeft: "0.25rem",
          color: "#4183c4",
          textDecoration: "none",
        }}
        onClick={() =>
          window.open(
            "https://my.kaart.com/password-reset",
            "_blank",
            "width=800, height=600"
          )
        }
      >
        Forgot password?
      </button>
      {!redirect ? <></> : <Navigate push to="/registerUser" />}
    </>
  );
};
