import { poster } from "../calls";

export async function createCamera(
  camera_make,
  camera_model,
  camera_facing,
  day_id
) {
  try {
    return await poster(
      {
        camera_facing: camera_facing,
        camera_make: camera_make,
        camera_model: camera_model,
        day_id: day_id,
      },
      "project/add_camera"
    );
  } catch (error) {
    console.error(error);
  }
}

export async function editCamera(camera_id, make = null, model = null) {
  try {
    return await poster(
      {
        camera_id: camera_id,
        camera_make: make,
        camera_model: model,
      },
      "project/edit_camera"
    );
  } catch (error) {
    console.error(error);
  }
}

export async function deleteCamera(camera_id, delete_originals) {
  try {
    return await poster(
      {
        camera_id: camera_id,
        delete_originals: delete_originals,
      },
      "project/delete_camera"
    );
  } catch (error) {
    console.error(error);
  }
}

export async function getCameraByVehicleId(vehicle_id, day_id) {
  try {
    return await poster(
      {
        vehicle_id: vehicle_id,
        day_id: day_id,
      },
      "project/fetch_vehicle_cameras"
    );
  } catch (error) {
    console.error(error);
  }
}
