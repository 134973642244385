// MODIFY Trip MODAL //
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import React, { useContext, useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";
import useToggle from "../../../hooks/useToggle";
import { Container, Modal, Table, TableBody, TableCell } from "@mui/material";
import { TableCard, TableEntryRow, Input } from "../../base/styles";
import { ModalWrapper } from "../../base/modalComponents";
import { ConfirmModalCommon } from "../../composite/confirmModal";
import { SortedTableHead } from "../../composite/sortedTableHead";
import { DataContext } from "../../../Contexts/DataContext";
import { editTrip } from "../../../services/tripService";
import { deleteCamera } from "../../../services/cameraService";
import { getImageUrlsBySequenceId } from "../../../services/imageService";
import JSZip from "jszip";
import { NewVehicleModal } from "../vehicleModals/createVehicleModal";
import { EditVehicleModal } from "../vehicleModals/editVehicleModal";
import { EditCameraModal } from "../cameraModals/editCameraModal";
import vehicle_icon from "../../../images/carIcon.png";
import camera_icon from "../../../images/cameraIcon.png";
import edit_icon from "../../../images/edit_icon.png";
import view_icon from "../../../images/view_icon.png";
import downloadIcon from "../../../images/download_icon.png";
import delete_icon from "../../../images/delete_icon.png";
import { Navigate } from "react-router-dom";
import { MetablockWrapper } from "../../../pages/Viewer/styles";
import { CloseButton } from "../../base/closeButton";
import { CancelButton, ConfirmButton } from "../../base/cancelConfirmButtons";
import { ProjectIcon } from "../../base/styles";
import { SectionTitle } from "../../base/sectionTitle";
import { SectionSubtitle } from "../../base/sectionSubtitle";
import { LoadingSpinner } from "../../base/loadingSpinner";
import { TableEntryCell } from "../../base/tableEntryCell";

const VEHICLE_DAYS_TABLE_HEAD = [
  // { id: "id", label: <strong>ID</strong>, alignLeft: true },
  { id: "day number", label: <strong>Day</strong>, alignLeft: true },
  { id: "date taken", label: <strong>Date</strong>, alignLeft: true },
  { id: "region", label: <strong>City/Region</strong>, alignLeft: true },
];

const SEQUENCE_TABLE_HEAD = [
  { id: "Index", label: <strong>Index</strong>, alignLeft: true },
  { id: "ID", label: <strong>ID</strong>, alignLeft: true },
  { id: "images", label: <strong>Total Images</strong>, alignLeft: true },
];

const IMAGE_TABLE_HEAD = [
  { id: "Index", label: <strong>Index</strong>, alignLeft: true },
  { id: "Timestamp", label: <strong>Total Images</strong>, alignLeft: true },
  { id: "Thumbnail", label: <strong>Thumbnail</strong>, alignLeft: true },
  { id: "blank", label: "", alignLeft: true },
];

export const ModifyTripModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.modifyOpen) {
        props.handleModifyOpen(); // Call the function to close the modal
      }
    }
  };
  const [addUserModalOpen, toggleAddUserModalOpen] = useToggle(false);

  return (
    <>
      <Modal open={props.modifyOpen} key="modify" onKeyDown={handleKeyDown}>
        <ModalWrapper>
          <CloseButton close_action={props.handleModifyOpen} />
          <SectionTitle
            title_text={<strong>Edit Trip: {props.tripName}</strong>}
          />
          <Container>
            <Tabs
              style={{
                textAlign: "center",
                height: "auto",
                width: "100%",
                paddingLeft: "0",
                paddingRight: "0",
              }}
            >
              <TabList>
                <>
                  <Tab>
                    <strong>Details</strong>
                  </Tab>
                  <Tab>
                    <strong>Footage</strong>
                  </Tab>
                  <Tab>
                    <strong>Status</strong>
                  </Tab>
                </>
              </TabList>
              {props.role === "admin" ? (
                <>
                  <TabPanel>
                    <EditNameSection
                      tripSelected={props.tripSelected}
                      tripName={props.tripName}
                      tripCountry={props.tripCountry}
                      tripCity={props.tripCity}
                      tripDescription={props.tripDescription}
                      handleModifyOpen={props.handleModifyOpen}
                      archive_button={props.archive_button}
                      completed_button={props.completed_button}
                      handleConfirmOpen={props.handleConfirmOpen}
                      addUserModalOpen={addUserModalOpen}
                      toggleAddUserModalOpen={toggleAddUserModalOpen}
                    />
                  </TabPanel>
                  <TabPanel>
                    <TripFootageTab
                      close_action={props.handleModifyOpen}
                      project_id={props.tripSelected}
                      tripSelected={props.tripSelected}
                      role={props.role}
                      handleImagePreviewOpen={props.handleImagePreviewOpen}
                      toggleImagePreviewOpen={props.toggleImagePreviewOpen}
                      selectedImageURL={props.selectedImageURL}
                      editOpen={props.editOpen}
                      handleEditOpen={props.handleEditOpen}
                      modifyOpen={props.modifyOpen}
                      toggleModifyOpen={props.toggleModifyOpen}
                    />
                  </TabPanel>

                  <TabPanel>
                    <EditStatusSection
                      tripStatus={props.tripStatus}
                      handleConfirmOpen={props.handleConfirmOpen}
                      handleSetStatusAction={props.handleSetStatusAction}
                      archiveTrip={props.archiveTrip}
                      completeTrip={props.completeTrip}
                      uploadingTrip={props.uploadingTrip}
                    />
                  </TabPanel>
                </>
              ) : (
                <></>
              )}
            </Tabs>
          </Container>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export const EditNameSection = (props) => {
  return (
    <>
      <ProjectDetailsSection
        tripName={props.tripName}
        tripCountry={props.tripCountry}
        tripCity={props.tripCity}
        tripDescription={props.tripDescription}
        addUserModalOpen={props.addUserModalOpen}
        toggleAddUserModalOpen={props.toggleAddUserModalOpen}
        handleModifyOpen={props.handleModifyOpen}
        cancel_text={"Cancel"}
        confirm_text={"Accept"}
        tripSelected={props.tripSelected}
      />
    </>
  );
};

export const EditStatusSection = (props) => {
  const handleStatusAction = (e) => {
    props.handleSetStatusAction(e);
    props.handleConfirmOpen();
  };

  return (
    <>
      <SectionTitle
        title_text={<strong>{`Current Status: ${props.tripStatus}`}</strong>}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "2vh",
        }}
      >
        <ConfirmButton
          confirm_text={"Set uploading"}
          confirm_action={() => handleStatusAction("Uploading")}
        />
        <ConfirmButton
          confirm_text={"Set Ready"}
          confirm_action={() => handleStatusAction("Ready")}
        />
        <ConfirmButton
          confirm_text={"Set Archived"}
          confirm_action={() => handleStatusAction("Archived")}
        />
        <ConfirmButton
          confirm_text={"Clear Bookmark"}
          confirm_action={() => handleStatusAction("Clear Bookmark")}
        />
      </div>
    </>
  );
};

export const ProjectDetailsSection = (props) => {
  const [tripName, setTripName] = useState(null);
  const [tripCity, setTripCity] = useState(null);
  const [tripCountry, setTripCountry] = useState(null);
  const [tripDesc, setTripDesc] = useState(null);
  const [tripSelected, setTripSelected] = useState(null);

  const { handleFetchOrgTrips } = useContext(DataContext);

  useEffect(() => {
    handleSetTripName(props.tripName);
    handleSetTripCountry(props.tripCountry);
    handleSetTripCity(props.tripCity);
    handleSetTripDesc(props.tripDescription);
    setTripSelected(props.tripSelected);
    // eslint-disable-next-line
  }, [props]);

  const handleSetTripName = (e) => {
    const value = e && e.target ? e.target.value : e;
    setTripName(value);
  };
  const handleSetTripCountry = (e) => {
    const value = e && e.target ? e.target.value : e;
    setTripCountry(value);
  };

  const handleSetTripCity = (e) => {
    const value = e && e.target ? e.target.value : e;
    setTripCity(value);
  };

  const handleSetTripDesc = (e) => {
    const value = e && e.target ? e.target.value : e;
    setTripDesc(value);
  };

  const handle_modify_trip_details = async (
    tripSelected,
    name,
    desc,
    city,
    country
  ) => {
    const response = await editTrip(tripSelected, name, desc, city, country);
    if (response.status === 200) {
      handleFetchOrgTrips("new");
      handleFetchOrgTrips("ready");
      handleFetchOrgTrips("archived");
    } else {
      console.error(response);
    }
    props.handleModifyOpen();
    setTripName(name);
    setTripCountry(country);
    setTripCity(city);
  };

  return (
    <>
      <SectionSubtitle subtitle_text={<strong>Name</strong>} />
      <Input
        style={{ margin: "auto", marginBottom: "2vh" }}
        value={tripName}
        type="text"
        name="name"
        placeholder="Name"
        onChange={(e) => handleSetTripName(e)}
      />
      <SectionSubtitle subtitle_text={<strong>City</strong>} />
      <Input
        style={{ margin: "auto", marginBottom: "2vh" }}
        value={tripCity}
        type="text"
        name="city"
        placeholder="City"
        onChange={(e) => handleSetTripCity(e)}
      />
      <SectionSubtitle subtitle_text={<strong>Country</strong>} />
      <Input
        style={{ margin: "auto", marginBottom: "2vh" }}
        value={tripCountry}
        type="text"
        name="country"
        placeholder="Country"
        onChange={(e) => handleSetTripCountry(e)}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <ModifyProjectButtons
          cancel_action={props.handleModifyOpen}
          cancel_text={"Cancel"}
          confirm_action={() =>
            handle_modify_trip_details(
              tripSelected,
              tripName,
              tripDesc,
              tripCity,
              tripCountry
            )
          }
          confirm_text={"Accept"}
          tripSelected={props.tripSelected}
        />
      </div>
    </>
  );
};

// MODIFY PROJECT BUTTONS //
export const ModifyProjectButtons = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
        marginBottom: "2vh",
      }}
    >
      <CancelButton
        cancel_action={props.cancel_action}
        cancel_text={props.cancel_text}
      />
      <ConfirmButton
        confirm_action={props.confirm_action}
        confirm_text={props.confirm_text}
      />
    </div>
  );
};

export const TripFootageTab = (props) => {
  const [page, setPage] = useState(1);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVehicleName, setSelectedVehicleName] = useState(null);
  const [selectedVehicleDays, setSelectedVehicleDays] = useState(null);
  const [selectedVehicleStartDate, setSelectedVehicleStartDate] =
    useState(null);
  const [selectedVehicleEndDate, setSelectedVehicleEndDate] = useState(null);
  const [selectedVehicleCameras, setSelectedVehicleCameras] = useState(null);
  const [selectedVehicleMake, setSelectedVehicleMake] = useState(null);
  const [selectedVehicleModel, setSelectedVehicleModel] = useState(null);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const [confirmOpen, toggleConfirmOpen] = useToggle(false);
  const [addVehicleModalOpen, toggleAddVehicleModalOpen] = useToggle(false);
  const [modalType, setModalType] = useState(null);
  const [modalTitleText, setModalTitleText] = useState(null);
  const [redirect, toggleRedirect] = useToggle(false);
  const [displayCameras, setDisplayCameras] = useState([]);
  const [deleteConfirmOpen, toggleDeleteConfirmOpen] = useToggle(false);
  const [editVehicleModalOpen, toggleEditVehicleOpen] = useToggle(false);

  const [editCameraModalOpen, toggleEditCameraOpen] = useToggle(false);
  const [addCameraModalOpen, toggleAddCameraOpen] = useToggle(false);

  const [modalQuestion, setModalQuestion] = useState("");
  const [modalInfo, setModalInfo] = useState("");
  const [deleteTarget, setDeleteTarget] = useState("Vehicle");
  const [modalButton1Text, setModalButton1Text] = useState("Cancel");
  const [modalButton2Text, setModalButton2Text] = useState("Delete");
  const [imageModalQuestion, setImageModalQuestion] = useState("");
  const [imageModalInfo, setImageModalInfo] = useState("");
  const [selectedCameraFacing, setSelectedCameraFacing] = useState(null);
  const [selectedCameraMake, setSelectedCameraMake] = useState(null);
  const [selectedCameraModel, setSelectedCameraModel] = useState(null);

  const {
    setSequenceDate,
    setSequenceFilename,
    setSelectionType,
    selectionType,
    fetchTripVehicles,
    tripVehicles,
    vehicleCameras,
    fetchVehicleCameras,
    fetchVehicleDays,
    vehicleDays,
    setVehicleDays,
    selectedSequence,
    fetch_sequence_images,
    delete_image,
    deleteSequence,
    fetch_image_details,
    selectedImage,
    setSelectedImage,
    editTripVehicle,
    ViewProjectSequencesClicked,
    tripSelected,
    toggleHasImagery,
    setcurrentFeatureCollectionIndex,
    setNewtripSelected,
    setSelectedPictureDirection,
    setSelectedFeature,
    setSelectedSequence,
  } = useContext(DataContext);

  useEffect(() => {
    fetchTripVehicles(props.tripSelected);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let facings = [];
    if (tripVehicles && tripVehicles.length > 0) {
      for (let i = 0; i < tripVehicles.length; i++) {
        let currentVehicle = tripVehicles[i];
        if (
          currentVehicle &&
          currentVehicle.cameras &&
          currentVehicle.cameras.length > 0
        ) {
          for (let i = 0; i < currentVehicle.cameras.length; i++) {
            let camera = currentVehicle.cameras[i];
            if (!facings.includes(camera.facing)) {
              facings.push(camera.facing);
            }
          }
        }
      }
    }
    setDisplayCameras(facings);
    // eslint-disable-next-line
  }, [tripVehicles]);

  useEffect(() => {
    if (selectedImage) {
      fetch_image_details(selectedImage);
    }

    // eslint-disable-next-line
  }, [selectedImage]);

  const handleSetPage = (direction) => {
    if (page === 1) {
      if (direction === "back") {
        props.close_action();
      } else {
        setPage(2);
      }
    }
    if (page === 2) {
      if (direction === "back") {
        setPage(1);
      } else {
        if (selectedDay) {
          setPage(3);
          fetchVehicleCameras(selectedVehicle, selectedDay);
        } else {
          alert("A Travel Day must first be selected");
        }
      }
    }
    if (page === 3) {
      if (direction === "back") {
        setPage(2);
      } else {
        props.close_action();
      }
    }
  };

  const handleSetSelectedVehicle = (
    id,
    name,
    days,
    startDate,
    endDate,
    cameras,
    make,
    model
  ) => {
    setSelectedVehicle(id);
    setSelectedVehicleName(name);
    setSelectedVehicleDays(days);
    setSelectedVehicleStartDate(startDate);
    setSelectedVehicleEndDate(endDate);
    setSelectedVehicleCameras(cameras);
    setSelectedVehicleMake(make);
    setSelectedVehicleModel(model);
    fetchVehicleDays(id);
  };

  const handleSetSelectedCamera = (id, facing, make, model) => {
    setSelectedCamera(id);
    setSelectedCameraFacing(facing);
    setSelectedCameraModel(model);
    setSelectedCameraMake(make);
  };

  const handleSetSelectedDay = (id) => {
    setSelectedDay(id);
  };

  const handleSetSelectedSequence = (
    id,
    video_filename,
    first_picture_date
  ) => {
    setSequenceFilename(video_filename);
    setSequenceDate(first_picture_date);
    setSelectedSequence(id);
  };

  const handleSetSelectedImage = (id, url) => {
    setSelectedImage(id);
    setSelectedImageURL();
    fetch_image_details(id);
  };

  const handleConfirmOpen = (id) => {
    setDeleteTarget("sequence");
    setModalQuestion(`Are you sure you want to delete the selected Sequence?`);
    setModalInfo(
      "-This action will delete the sequence and all of the images and vector tiles associated with this sequence. Clearing your browser cached vector tiles is recommended so that the changes will be reflected on the map."
    );
    setModalButton2Text("Cancel");
    setModalButton2Text("Delete");
    toggleDeleteConfirmOpen();
  };

  const handleDeleteVehicleOpen = () => {
    setDeleteTarget("Vehicle");
    setModalQuestion("Are you sure you want to delete this Vehicle?");
    setModalInfo(
      "CAUTION: This will also delete any Cameras, Travel Days, Sequences and Images associated with this Vehicle!"
    );
    handleDeleteConfirmOpen();
  };

  const handleDeleteImage = () => {
    delete_image(selectedImage);
    toggleConfirmOpen(false);
    fetch_sequence_images(selectedSequence);
  };

  const handleDeleteSequence = () => {
    toggleDeleteConfirmOpen();
    setModalQuestion(
      `Would you like to delete the original images in cloud storage as well?`
    );
    setModalInfo(
      "CAUTION! This action CANNOT be undone! Delete these images by accident and you will never hear the end of it!"
    );
    setModalButton1Text("Preserve");
    setModalButton2Text("Delete Originals");
    toggleDeleteConfirmOpen();
  };

  const handleConfirmDeleteToAnnoyUser = () => {
    toggleDeleteConfirmOpen();
    setModalQuestion(
      `...Yeah but are you like 100%, ABSOLUTELY positive you want to delete the original images from the cloud?`
    );
    setModalInfo(
      "It can't be stressed enough how much this action CANNOT be undone. Deleting these images by accident will place GREAT dishonor on you and and your house, and your computer will redirect all URLs to a Portuguese gaming website until the end of time."
    );
    setModalButton1Text("Preserve");
    setModalButton2Text("Yes, Delete");
    toggleDeleteConfirmOpen();
  };

  const handleSetDeleteOriginals = () => {
    handleDeleteProceed(true);
  };

  const handleDeleteProceed = (bool) => {
    if (deleteTarget === "sequence" && bool === true) {
      deleteSequence(bool);
    }
    if (deleteTarget === "Camera" && bool === true) {
      deleteCamera(selectedCamera, bool);
    }
    toggleDeleteConfirmOpen();
  };

  const handleViewSelectedImage = (image_id, image_index, image_facing) => {
    ViewProjectSequencesClicked(
      props.tripSelected,
      selectedSequence,
      image_id,
      image_index,
      image_facing,
      true
    );
    toggleRedirect();
  };

  const handleDeleteVehicle = () => {
    handleDeleteConfirmOpen(false);
  };

  const handleEditVehicle = () => {
    editTripVehicle(
      selectedVehicle,
      selectedVehicleName,
      selectedVehicleCameras,
      selectedVehicleDays
    );
  };

  const handleEditVehicleOpen = (e = null) => {
    setModalType("Edit");
    if (e) {
      toggleEditVehicleOpen(e);
    } else {
      toggleEditVehicleOpen();
    }
  };

  const handleAddCameraOpen = (e = null) => {
    setModalType("Add");
    if (e) {
      toggleEditCameraOpen(e);
    } else {
      toggleEditCameraOpen();
    }
  };

  const handleEditCameraOpen = (e = null) => {
    setModalType("Edit");
    if (e) {
      toggleEditCameraOpen(e);
    } else {
      toggleEditCameraOpen();
    }
  };

  const handleAddVehicleOpen = (e) => {
    setModalType("Add");
    if (e) {
      toggleAddVehicleModalOpen(e);
    } else {
      toggleAddVehicleModalOpen();
    }
    setPage(1);
  };

  const handleDeleteDayOpen = () => {
    setDeleteTarget("Day");
    setModalQuestion("Are you sure you want to delete this Day of Travel?");
    setModalInfo(
      "CAUTION: This will also delete any Cameras, Sequences and Images associated with this Day of Travel!"
    );
    handleDeleteConfirmOpen();
  };

  const handleDeleteCameraOpen = () => {
    setDeleteTarget("Camera");
    setModalQuestion("Are you sure you want to delete this Camera?");
    setModalInfo(
      "CAUTION: This will also delete any Days, Sequences and Images associated with this Camera and cannot be undone!"
    );
    handleDeleteConfirmOpen();
  };

  const handleDelete = () => {
    // if (deleteTarget === "Vehicle") {
    //   handleDeleteVehicle();
    // }
    // if (deleteTarget === "Day") {
    //   handleDeleteDay();
    // }
  };

  const handleViewTrip = (id) => {
    setSelectedPictureDirection(null);
    setSelectedFeature(null);
    setSelectedSequence(null);
    setNewtripSelected(true);

    setcurrentFeatureCollectionIndex(0);
    ViewProjectSequencesClicked(tripSelected, id);
    // setSelectedView("Front");
    toggleHasImagery(true);
    toggleRedirect();
  };

  const handleDeleteConfirmOpen = (bool) => {
    if (bool) {
      toggleDeleteConfirmOpen(bool);
    } else {
      toggleDeleteConfirmOpen();
    }
  };

  const handleSetModalTitleText = (e) => {
    setModalTitleText(e);
  };

  function formatDate(datetime) {
    const date = new Date(datetime);
    const timezoneOffset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - timezoneOffset * 60 * 1000);
    const month = adjustedDate.getMonth() + 1; // Months are 0-indexed
    const day = adjustedDate.getDate();
    const year = adjustedDate.getFullYear() % 100; // Extract last two digits of the year
    return `${month}/${day}/${year}`;
  }

  const fetchUrls = () => {
    toggleDeleteConfirmOpen();
    fetch_image_urls(selectedSequence);
  };

  const fetch_image_urls = async (sequence_id) => {
    const response = await getImageUrlsBySequenceId(sequence_id);
    if (response.status === 200) {
      const imageUrls = response.image_urls;
      let videoFilename = response.video_filename;
      await downloadImagesAndZip(imageUrls, videoFilename);
    } else {
      console.error(response);
    }
  };

  const MAX_RETRY = 3;
  const downloadImagesAndZip = async (imageUrls, videoFilename) => {
    const zip = new JSZip();

    await Promise.all(
      imageUrls.map(async (imageUrl) => {
        let retryCount = 0;
        let success = false;

        while (retryCount < MAX_RETRY && !success) {
          try {
            const response = await fetch(imageUrl, {
              responseType: "arraybuffer",
            });
            const arrayBuffer = await response.arrayBuffer();

            const filename = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
            const blob = new Blob([arrayBuffer]);

            zip.file(filename, blob);
            success = true;
          } catch (error) {
            console.error(`Error downloading image from ${imageUrl}:`, error);
            retryCount++;
          }
        }
      })
    );

    const content = await zip.generateAsync({ type: "blob" });

    if (videoFilename !== "N/A") {
      try {
        let fileNameWithoutExtension = videoFilename
          .replace(/^.*[\\/]/, "")
          .split(".")
          .slice(0, -1)
          .join(".");

        saveAs(content, fileNameWithoutExtension);
      } catch {
        saveAs(content, "images.zip");
      }
    } else {
      saveAs(content, "images.zip");
    }
  };

  const handleFetchImageUrls = (id) => {
    setModalButton2Text("Download");
    setModalQuestion(
      "Are you sure you want to download this entire sequence of images?"
    );
    setModalInfo(
      "This could take several minutes depending on the length of the sequence. The zipped folder will appear in your downloads folder when complete"
    );
    handleDeleteConfirmOpen();
  };

  return (
    <>
      <ConfirmModalCommon
        modal_open={deleteConfirmOpen}
        handleOpenCloseModal={handleDeleteConfirmOpen}
        interrogative={modalQuestion}
        informative={modalInfo}
        button_1_text={modalButton1Text}
        button_2_text={modalButton2Text}
        button_2_action={
          modalButton2Text === "Delete"
            ? handleDeleteSequence
            : modalButton2Text === "Delete Originals"
            ? handleConfirmDeleteToAnnoyUser
            : modalButton2Text === "Yes, Delete"
            ? handleSetDeleteOriginals
            : () => fetchUrls()
        }
        button_1_action={
          modalButton1Text === "Cancel"
            ? handleDeleteConfirmOpen
            : modalButton1Text === "Preserve"
            ? (e) => handleSetDeleteOriginals(false)
            : (e) => console.log("YO")
        }
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "45vw",
          height: "70vh",
          margin: "auto",
          marginTop: "0vh",
        }}
      >
        {page === 1 ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "60vh",
                overflowY: "scroll",
                boxShadow: "1px 1px 6px 2px gray",
              }}
            >
              {tripVehicles &&
                tripVehicles.slice().map((row) => {
                  const {
                    id,
                    name,
                    travel_days,
                    start_date,
                    end_date,
                    cameras,
                    make,
                    model,
                  } = row;
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "2vh",
                        height: "20vh",
                        width: "90%",
                        boxShadow: "1px 1px 6px 2px gray",
                        marginBottom: "2vh",
                      }}
                      key={uuidv4()}
                    >
                      <ProjectIcon
                        src={vehicle_icon}
                        key={uuidv4()}
                        style={{
                          margin: "auto",
                          marginLeft: "1vw",
                          height: "3vh",
                          width: "auto",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "15vh",
                          width: "80%",
                          alignItems: "left",
                          justifyContent: "left",
                          margin: "auto",
                        }}
                        key={uuidv4()}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "left",
                            marginLeft: "0vw",
                          }}
                          key={uuidv4()}
                        >
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Vehicle:</strong>}
                            key={uuidv4()}
                          />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={name}
                            key={uuidv4()}
                          />
                          <span style={{ width: "2vw" }} />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Make:</strong>}
                            key={uuidv4()}
                          />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={make}
                            key={uuidv4()}
                          />

                          <span style={{ width: "2vw" }} />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Model:</strong>}
                            key={uuidv4()}
                          />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={model}
                            key={uuidv4()}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "left",
                          }}
                          key={uuidv4()}
                        >
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Total Days:</strong>}
                            key={uuidv4()}
                          />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={travel_days}
                            key={uuidv4()}
                          />
                          <span style={{ width: "2vw" }} />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Dates:</strong>}
                            key={uuidv4()}
                          />
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={`${formatDate(
                              start_date
                            )}-${formatDate(end_date)}`}
                            key={uuidv4()}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "left",
                          }}
                          key={uuidv4()}
                        >
                          <SectionSubtitle
                            align={"left"}
                            subtitle_text={<strong>Cameras:</strong>}
                            key={uuidv4()}
                          />
                          {cameras &&
                            cameras.slice().map((camera) => {
                              return (
                                <SectionSubtitle
                                  align={"left"}
                                  subtitle_text={camera}
                                  key={uuidv4()}
                                />
                              );
                            })}
                        </div>
                      </div>
                      <input
                        type="radio"
                        value={id}
                        style={{ marginRight: "2vw" }}
                        onChange={(e) =>
                          handleSetSelectedVehicle(
                            id,
                            name,
                            travel_days,
                            start_date,
                            end_date,
                            cameras,
                            make,
                            model
                          )
                        }
                        checked={selectedVehicle === id}
                        key={uuidv4()}
                      />
                    </div>
                  );
                })}
            </div>
          </>
        ) : page === 2 ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "auto",
                  width: "100%",
                  // backgroundColor:'green'
                }}
              >
                <SectionTitle
                  title_text={"Select Day of Travel:"}
                  paddingLeft={"2vw"}
                  paddingRight={"2vw"}
                />
                {/*<ConfirmButton*/}
                {/*  // confirm_action={() => handleAddVehicleOpen()}*/}
                {/*  confirm_text={"Add"}*/}
                {/*/>*/}
                {/*<ConfirmButton*/}
                {/*  // confirm_action={() => handleEditVehicleOpen()}*/}
                {/*  confirm_text={"Edit"}*/}
                {/*/>*/}
                <ConfirmButton
                  confirm_action={() => handleDeleteDayOpen()}
                  confirm_text={"Delete"}
                />
              </div>

              <TableCard
                style={{
                  boxShadow: "1px 1px 6px 2px gray",
                  margin: "auto",
                  height: "50vh",
                  width: "90%",
                  marginTop: "3vh",
                  marginBottom: "2vh",
                  overflowY: "scroll",
                }}
              >
                <Table style={{ height: "auto" }}>
                  <SortedTableHead
                    // sortTrips={sortTrips}
                    headLabel={VEHICLE_DAYS_TABLE_HEAD}
                    // operator={sortOperator}
                    tableData={vehicleDays}
                    updateData={setVehicleDays}
                    vw={"45vw"}
                  />
                  <TableBody>
                    {vehicleDays &&
                      vehicleDays.slice().map((row) => {
                        const { id, day_of_travel, date, city, cloud_path } =
                          row;
                        return (
                          <TableEntryRow
                            sx={{
                              "&:hover": {
                                backgroundColor: "rgba(145, 165, 172, 0.5)",
                                cursor: "pointer",
                              },
                            }}
                            align="center"
                            // hover
                            key={id}
                            tabIndex={-1}
                            onClick={() => handleSetSelectedDay(id)}
                            selected={selectedDay === id}
                            // onDoubleClick={() => view_all_project_sequences(id)}
                          >
                            <TableEntryCell
                              entry={<strong>{day_of_travel}</strong>}
                            />
                            <TableEntryCell entry={date ? date : "N/A"} />
                            <TableEntryCell entry={city ? city : "N/A"} />
                          </TableEntryRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableCard>
            </div>
          </>
        ) : page === 3 ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "auto",
                width: "100%",
                // backgroundColor:'green'
              }}
            >
              <SectionTitle
                title_text={"Select Camera:"}
                paddingLeft={"2vw"}
                paddingRight={"2vw"}
              />
              <ConfirmButton
                confirm_action={() => handleAddCameraOpen()}
                confirm_text={"Add"}
              />
              <ConfirmButton
                confirm_action={() => handleEditCameraOpen()}
                confirm_text={"Edit"}
              />
              <ConfirmButton
                confirm_action={() => handleDeleteCameraOpen()}
                confirm_text={"Delete"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "60vh",
                overflowY: "scroll",
                boxShadow: "1px 1px 6px 2px gray",
              }}
            >
              {vehicleCameras &&
                vehicleCameras
                  .slice()
                  .sort((a, b) => {
                    const facingOrder = ["Front", "Left", "Right", "360"];
                    const indexA = facingOrder.indexOf(a.facing);
                    const indexB = facingOrder.indexOf(b.facing);
                    return indexA - indexB;
                  })

                  .map((row) => {
                    const { id, facing, type, model } = row;
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "2vh",
                          height: "15vh",
                          width: "60%",
                          boxShadow: "1px 1px 6px 2px gray",
                          marginBottom: "2vh",
                        }}
                        key={uuidv4()}
                      >
                        <ProjectIcon
                          src={camera_icon}
                          style={{
                            margin: "auto",
                            marginLeft: "1vw",
                            height: "3vh",
                            width: "auto",
                          }}
                          key={uuidv4()}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "15vh",
                            width: "80%",
                            alignItems: "left",
                            justifyContent: "left",
                            margin: "auto",
                          }}
                          key={uuidv4()}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "left",
                              marginLeft: "0vw",
                            }}
                            key={uuidv4()}
                          >
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={<strong>Camera Facing:</strong>}
                              key={uuidv4()}
                            />
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={<strong>{facing}</strong>}
                              key={uuidv4()}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "left",
                            }}
                            key={uuidv4()}
                          >
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={<strong>Make:</strong>}
                              key={uuidv4()}
                            />
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={type ? type : "N/A"}
                              key={uuidv4()}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "left",
                            }}
                            key={uuidv4()}
                          >
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={<strong>Model:</strong>}
                              key={uuidv4()}
                            />
                            <SectionSubtitle
                              align={"left"}
                              subtitle_text={model ? model : "N/A"}
                              key={uuidv4()}
                            />
                          </div>
                        </div>
                        <input
                          type="radio"
                          value={id}
                          style={{ marginRight: "3vw" }}
                          onChange={(e) =>
                            handleSetSelectedCamera(id, facing, type, model)
                          }
                          checked={selectedCamera === id}
                          key={uuidv4()}
                        />
                      </div>
                    );
                  })}
            </div>
          </>
        ) : (
          <></>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            textAlign: "center",
            justifyContent: "center",
            marginTop: "2vh",
            marginBottom: "2vh",
          }}
        >
          <CancelButton
            cancel_action={(e) => handleSetPage("back")}
            cancel_text={page === 1 ? "Cancel" : "Back"}
          />
          {page !== 5 ? (
            <>
              <ConfirmButton
                confirm_action={() => handleSetPage("next")}
                confirm_text={"Next"}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {!redirect ? null : <Navigate push to="/viewer" />}
    </>
  );
};

export const Thumbnail = ({ src, alt, maxWidth, maxHeight }) => {
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);

  const handleImageLoad = () => {
    setThumbnailLoaded(true);
  };

  return (
    <div
      style={{
        position: "relative",
        width: maxWidth,
        height: maxHeight,
        overflow: "hidden",
        // backgroundColor:'green'
      }}
      key={uuidv4()}
    >
      {!thumbnailLoaded && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            marginTop: "0vh",
            padding: "0",
          }}
          key={uuidv4()}
        >
          Loading...
        </div>
      )}
      <img
        src={src}
        alt={alt}
        style={{
          display: thumbnailLoaded ? "block" : "none",
          maxWidth: thumbnailLoaded ? "100%" : "0",
          maxHeight: thumbnailLoaded ? "100%" : "0",
          width: thumbnailLoaded ? "auto" : "0",
          height: thumbnailLoaded ? "auto" : "0",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "0",
          objectFit: "cover",
          objectPosition: "center",
        }}
        key={uuidv4()}
        onLoad={handleImageLoad}
      />
    </div>
  );
};
