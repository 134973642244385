import React, { useContext, useEffect, useState } from "react";
import csvtojson from "csvtojson";
import { poster } from "../../calls";
import { AuthContext } from "../../Contexts/AuthContext";
import useToggle from "../../hooks/useToggle.js";
import { Table, TableBody } from "@mui/material";
import { Navigate } from "react-router-dom";
import {
  CardMediaStyle,
  TableCard,
  TableEntryRow,
  TopDiv,
} from "../../components/base/styles";
import {
  deleteUser,
  editUser,
  getUsers,
  inviteUserByEmail,
} from "../../services/userService";
import { ButtonDivComponent } from "../../components/composite/buttonDivComponent";
import { TableEntryCell } from "../../components/base/tableEntryCell";
import { SortedTableHead } from "../../components/composite/sortedTableHead";
import { ModifyUserModal } from "../../components/features/userModals/modifyUserModal";
import { DeleteUserModal } from "../../components/features/userModals/deleteUserModal";
import { AddUserModal } from "../../components/features/userModals/createUserModal";
import CustomTable from "../../components/composite/table/customTable";

export const AdminUsersPage = () => {
  const columnKeys = ["name", "role", "joined"];

  const { user } = useContext(AuthContext);
  const [orgUsers, setOrgUsers] = useState([]);
  const [addOpen, toggleAddOpen] = useToggle(false);
  const [deleteOpen, toggleDeleteOpen] = useToggle(false);
  const [modifyOpen, toggleModifyOpen] = useToggle(false);
  const [userSelected, setUserSelected] = useState(null);
  const [userSelectedName, setUserSelectedName] = useState(null);
  const [roleSelected, setRoleSelected] = useState(null);
  const [inviteEmail, setInviteEmail] = useState(null);
  const [form, setForm] = useState({ name: "", desc: "" });
  const [redirect, setRedirect] = useState(false);
  // const fileInputRef = useRef(null);

  useEffect(() => {
    if (user === null) {
      setRedirect(true);
    }
    if (user !== null && user.role !== "admin") {
      setRedirect(true);
    }
    fetchOrgUsers();
    // eslint-disable-next-line
  }, []);

  const handleAddOpen = () => {
    toggleAddOpen(!addOpen);
  };

  const handleDeleteOpen = () => {
    if (userSelected !== null) {
      toggleDeleteOpen();
    } else {
      alert("No User Selected");
    }
  };

  const handleModifyOpen = () => {
    if (userSelected !== null) {
      toggleModifyOpen();
    } else {
      alert("No User Selected");
    }
  };

  const handleSetUserSelected = (rowData) => {
    setUserSelected(rowData["value"]);
    setUserSelectedName(rowData["name"]);
  };

  const handleRoleSelected = (e) => {
    setRoleSelected(e);
  };

  const handleSetInviteEmail = (e) => {
    setInviteEmail(e);
  };

  const fetchOrgUsers = async () => {
    const response = await getUsers();
    if (response.status === 200) {
      setOrgUsers(response.users);
    } else {
      console.error(response);
    }
  };

  const modifyUser = async (user_id, role) => {
    const response = await editUser(user_id, role);
    if (response.status === 200) {
      fetchOrgUsers();
    } else {
      console.error(response);
    }
  };

  const removeUser = async (user_id) => {
    const response = await deleteUser(user_id);
    if (response.status === 200) {
      fetchOrgUsers();
    } else {
      console.error(response);
    }
  };

  const inviteUser = async (email) => {
    const response = await inviteUserByEmail(email);
    if (response.status === 200) {
      alert(response.message);
    } else {
      console.error(response);
    }
  };

  const do_modify_user = () => {
    if (userSelected) {
      modifyUser(userSelected, roleSelected);
      toggleModifyOpen();
    }
  };

  const do_remove_user = () => {
    if (userSelected) {
      removeUser(userSelected);
      toggleDeleteOpen();
    }
  };

  const do_invite_user = () => {
    if (inviteEmail) {
      inviteUser(inviteEmail, "mikro");
      alert("Invitation Email Sent");
      toggleAddOpen();
    }
  };

  const handleFileSelect = async (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileContents = event.target.result;
        const json = await csvtojson().fromString(fileContents);
        try {
          let response = poster(json, "user/import_users");
        } catch (error) {
          console.error("Error importing users:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  const importFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.onchange = handleFileSelect;
    input.click();
  };

  return (
    <>
      <AddUserModal
        addOpen={addOpen}
        form={form}
        handleAddOpen={handleAddOpen}
        handleSetInviteEmail={handleSetInviteEmail}
        setForm={setForm}
        do_invite_user={do_invite_user}
      />
      <DeleteUserModal
        deleteOpen={deleteOpen}
        selected_user={userSelected}
        userSelectedName={userSelectedName}
        handleDeleteOpen={handleDeleteOpen}
        do_remove_user={do_remove_user}
      />
      <ModifyUserModal
        modifyOpen={modifyOpen}
        roleSelected={roleSelected}
        handleModifyOpen={handleModifyOpen}
        handleRoleSelected={handleRoleSelected}
        do_modify_user={do_modify_user}
      />

      <TopDiv>
        <ButtonDivComponent
          buttons={[
            { action: handleAddOpen, text: "Add" },
            { action: handleModifyOpen, text: "Edit" },
            { action: handleDeleteOpen, text: "Delete" },
            // { action: importFile, text: "Import" },
          ]}
        />
      </TopDiv>

      <CustomTable
        headers={columnKeys}
        data={orgUsers}
        columns={columnKeys}
        handleOnClick={handleSetUserSelected}
        wrapperClassName="mt-10 mx-4"
      />
    </>
  );
};
