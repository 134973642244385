import React, { useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Modal, Table, TableBody } from "@mui/material";
import { DataContext } from "../../../Contexts/DataContext";
import folder_icon from "../../../images/folder_icon.png";
import {
  TableCard,
  TableEntryRow,
  CardMediaStyle,
  ProjectIcon,
} from "../../base/styles";
import { SelectionRow } from "./selectionRow";
import { CameraUIcard, VehicleUIcard, TravelDayUIcard } from "./uploadCards";
import { CloseButton } from "../../base/closeButton";
import { CancelButton, ConfirmButton } from "../../base/cancelConfirmButtons";
import { SectionTitle } from "../../base/sectionTitle";
import { SectionSubtitle } from "../../base/sectionSubtitle";
import { TableEntryCell } from "../../base/tableEntryCell";
import { SortedTableHead } from "../../composite/sortedTableHead";
import { ModalWrapper } from "../../base/modalComponents";

export const SELECT_TRIPS_TABLE_HEAD = [
  { id: "name", label: <strong>Name</strong>, alignLeft: true },
  { id: "id", label: <strong>ID</strong>, alignLeft: true },
  { id: "status", label: <strong>Status</strong>, alignLeft: true },
  { id: "country", label: <strong>Country</strong>, alignLeft: true },
  { id: "city", label: <strong>City</strong>, alignLeft: true },
  { id: "vehicles", label: <strong>Vehicles</strong>, alignLeft: true },
  { id: "days", label: <strong>Travel days</strong>, alignLeft: true },
];

export const DAYS_TABLE_HEAD = [
  { id: "id", label: <strong>ID</strong>, alignLeft: true },
  { id: "vehicle", label: <strong>Vehicle</strong>, alignLeft: true },
  { id: "day number", label: <strong>Day</strong>, alignLeft: true },
  { id: "date taken", label: <strong>Date</strong>, alignLeft: true },
  { id: "region", label: <strong>City/Region</strong>, alignLeft: true },
];

const spinnerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const circleStyle = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  border: "4px solid #f3f3f3",
  borderTop: "4px solid #3498db",
  animation: "spin 1s linear infinite",
  marginBottom: "10px",
};

const spinAnimation = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const USER_TABLE_HEAD = [
  { id: "name", label: <strong>User Name</strong>, alignLeft: true },
  { id: "role", label: <strong>Role</strong>, alignLeft: true },
  { id: "joined", label: <strong>Joined</strong>, alignLeft: true },
];

export const RoleDiv = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  margin: "auto",
  alignItems: "center",
  justifyContent: "center",
  justifyItems: "center",
  marginBottom: "2vh",
  marginTop: "2vh",
}));

export const SelectTripTable = (props) => {
  const { newTrips, fetchOrgTrips } = useContext(DataContext);

  useEffect(() => {
    fetchOrgTrips("new");
  }, []);
  return (
    <>
      <SectionSubtitle subtitle_text={"Select the Trip for the upload"} />
      <div
        style={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          marginLeft: "3.5vh",
          marginRight: "3.5vh",
          marginTop: "1vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "42vh",
          }}
        >
          <TableCard
            style={{
              boxShadow: "1px 1px 6px 2px gray",
              overflowY: "scroll",
              width: "100%",
            }}
          >
            <CardMediaStyle />
            <Table style={{ height: "100%" }}>
              <SortedTableHead
                // sortTrips={sortTrips}
                headLabel={SELECT_TRIPS_TABLE_HEAD}
                tableData={newTrips}
                updateData={fetchOrgTrips}
                vw={"35vw"}
              />
              <TableBody>
                {newTrips &&
                  newTrips.slice().map((row) => {
                    const {
                      name,
                      id,
                      status,
                      country,
                      city,
                      total_days,
                      vehicles,
                    } = row;
                    return (
                      <TableEntryRow
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(145, 165, 172, 0.5)",
                            cursor: "pointer",
                          },
                        }}
                        align="center"
                        // hover
                        key={id}
                        tabIndex={-1}
                        onClick={() => props.handleTripSelected(id, name)}
                        selected={props.tripSelected === id}
                      >
                        <TableEntryCell entry={<strong>{name}</strong>} />
                        <TableEntryCell entry={id} />
                        <TableEntryCell entry={status} />
                        <TableEntryCell entry={country} />
                        <TableEntryCell entry={city} />
                        <TableEntryCell entry={vehicles} />
                        <TableEntryCell entry={total_days} />
                      </TableEntryRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableCard>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: "2vh",
            marginTop: "1vw",
          }}
        >
          <CancelButton
            cancel_text={"Back"}
            cancel_action={
              props.selectionPage === 1 ? () => props.handlePageBack() : null
            }
          />
          <ConfirmButton
            confirm_text={
              props.selectionPage !== 3 && props.processSubfolders === false
                ? "Next"
                : "Select"
            }
            confirm_action={
              props.selectionPage !== 3 && props.processSubfolders === false
                ? () => props.handleSetSelectionPage(2)
                : props.processSubfolders === true
                ? () => props.handleSetSelectionPage(5)
                : null
            }
          />
        </div>
      </div>
    </>
  );
};

export const SelectDayTable = (props) => {
  const {
    fetchVehicleDays,
    vehicleDays,
    selectedVehicle,
    selectedDay,
    setSelectedDay,
    selectedCamera,
    setDayOfTravel,
  } = useContext(DataContext);

  useEffect(() => {
    fetchVehicleDays(selectedVehicle, selectedCamera);
    // eslint-disable-next-line
  }, []);

  const handleSetSelectedDay = (id, day_of_travel, cloud_path) => {
    setSelectedDay(id);
    setDayOfTravel(day_of_travel);
    props.setSelectedTripCloudPath(cloud_path);
  };
  return (
    <>
      <SectionSubtitle
        subtitle_text={
          <strong>Select the day of travel for the upload:</strong>
        }
      />
      <TableCard
        style={{
          boxShadow: "1px 1px 6px 2px gray",
          margin: "auto",
          height: "50vh",
          width: "90%",
          marginTop: "3vh",
          marginBottom: "2vh",
        }}
      >
        {vehicleDays &&
          vehicleDays.slice().map((row) => {
            const { id, day_of_travel, date, city, cloud_path } = row;
            return (
              <TravelDayUIcard
                selectedDay={selectedDay}
                day_of_travel={day_of_travel}
                date={date}
                city={city}
                id={id}
                cloud_path={cloud_path}
                handleSetSelectedDay={handleSetSelectedDay}
              />
            );
          })}
      </TableCard>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "2vh",
        }}
      >
        <CancelButton
          cancel_text={"Back"}
          cancel_action={
            props.selectionPage !== 6 ? () => props.handlePageBack() : null
          }
        />
        <ConfirmButton
          confirm_text={props.selectionPage !== 4 ? "Next" : "Select"}
          confirm_action={
            props.selectionPage !== 6
              ? () => props.handleSetSelectionPage(props.selectionPage + 1)
              : null
          }
        />
      </div>
    </>
  );
};

export const SelectVehicleTable = (props) => {
  const {
    selectedTripDays,
    setSelectedTripDays,
    fetchTripVehicles,
    tripVehicles,
    selectedVehicle,
    setSelectedVehicle,
    setSelectedVehicleName,
  } = useContext(DataContext);

  useEffect(() => {
    fetchTripVehicles(props.tripSelected);
    // eslint-disable-next-line
  }, []);

  const handleSetSelectedVehicle = (id, name) => {
    setSelectedVehicle(id);
    setSelectedVehicleName(name);
  };
  return (
    <>
      <SectionSubtitle
        subtitle_text={<strong>Select the Vehicle for the upload:</strong>}
      />
      {tripVehicles &&
        tripVehicles.slice().map((row) => {
          const { id, name, travel_days, cameras } = row;
          return (
            <VehicleUIcard
              id={id}
              name={name}
              travel_days={travel_days}
              cameras={cameras}
              selectedVehicle={selectedVehicle}
              handleSetSelectedVehicle={handleSetSelectedVehicle}
            />
          );
        })}

      {props.selectedVehicle ? (
        <>
          <TableCard
            style={{
              boxShadow: "1px 1px 6px 2px gray",
              width: "90%",
              margin: "auto",
              marginBottom: "2vh",
              marginTop: "3vh",
            }}
          >
            <Table style={{ height: "100%" }}>
              <div
                style={{ height: "50vh", width: "auto", overflowY: "scroll" }}
              >
                <SortedTableHead
                  headLabel={DAYS_TABLE_HEAD}
                  tableData={selectedTripDays}
                  updateData={setSelectedTripDays}
                  vw={"35vw"}
                />
                <TableBody>
                  {selectedTripDays &&
                    selectedTripDays.slice().map((row) => {
                      const { id, date, city, day_of_travel, device, facings } =
                        row;
                      return (
                        <>
                          {props.selectedVehicle === device ? (
                            <>
                              <TableEntryRow
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "rgba(145, 165, 172, 0.5)",
                                    cursor: "pointer",
                                  },
                                }}
                                align="center"
                                key={id}
                                tabIndex={-1}
                                onClick={() => props.handleDaySelected(id)}
                                selected={props.selectedTripDays === id}
                              >
                                <TableEntryCell entry={id} />
                                <TableEntryCell entry={device} />
                                <TableEntryCell entry={day_of_travel} />
                                <TableEntryCell entry={date} />
                                <TableEntryCell entry={city} />
                                <TableEntryCell entry={facings} />
                              </TableEntryRow>
                            </>
                          ) : null}
                        </>
                      );
                    })}
                </TableBody>
              </div>
            </Table>
          </TableCard>
        </>
      ) : null}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "2vh",
        }}
      >
        <CancelButton
          cancel_text={"Back"}
          cancel_action={
            props.selectionPage !== 3 ? () => props.handlePageBack() : null
          }
        />
        <ConfirmButton
          confirm_text={props.selectionPage !== 3 ? "Next" : "Select"}
          confirm_action={
            props.selectionPage !== 3
              ? () => props.handleSetSelectionPage(props.selectionPage + 1)
              : null
          }
        />
      </div>
    </>
  );
};

export const SelectCameraTable = (props) => {
  const {
    fetchVehicleCameras,
    vehicleCameras,
    selectedCamera,
    setSelectedCamera,
    setSelectedCameraFacing,
    selectedVehicle,
  } = useContext(DataContext);

  useEffect(() => {
    fetchVehicleCameras(selectedVehicle);
    // eslint-disable-next-line
  }, []);

  const handleSetSelectedCamera = (id, facing) => {
    setSelectedCamera(id);
    setSelectedCameraFacing(facing);
  };
  return (
    <>
      <SectionSubtitle
        subtitle_text={<strong>Select the Camera for the upload:</strong>}
      />
      {vehicleCameras &&
        vehicleCameras.slice().map((row) => {
          const { id, facing, type, model } = row;
          return (
            <CameraUIcard
              facing={facing}
              id={id}
              type={type}
              model={model}
              selectedCamera={selectedCamera}
              handleSetSelectedCamera={handleSetSelectedCamera}
            />
          );
        })}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "2vh",
        }}
      >
        <CancelButton
          cancel_text={"Back"}
          cancel_action={
            props.selectionPage !== 5 ? () => props.handlePageBack() : null
          }
        />
        <ConfirmButton
          confirm_text={props.selectionPage !== 3 ? "Next" : "Select"}
          confirm_action={
            props.selectionPage !== 5
              ? () => props.handleSetSelectionPage(props.selectionPage + 1)
              : null
          }
        />
      </div>
    </>
  );
};

export const ReviewUploadDestinationDetails = (props) => {
  const { selectedVehicleName, dayOfTravel, selectedCameraFacing } =
    useContext(DataContext);

  return (
    <>
      <SectionSubtitle
        subtitle_text={<strong>Review details and confirm:</strong>}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "2vh",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <SectionSubtitle subtitle_text={<strong>Selected Trip:</strong>} />
          <SectionSubtitle subtitle_text={props.tripSelectedName} />
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <SectionSubtitle subtitle_text={<strong>Selected Vehicle:</strong>} />
          <SectionSubtitle subtitle_text={selectedVehicleName} />
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <SectionSubtitle subtitle_text={<strong>Selected Day:</strong>} />
          <SectionSubtitle subtitle_text={dayOfTravel} />
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <SectionSubtitle subtitle_text={<strong>Selected Camera:</strong>} />
          <SectionSubtitle subtitle_text={selectedCameraFacing} />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "2vh",
        }}
      >
        <CancelButton
          cancel_text={"Back"}
          cancel_action={
            props.selectionPage !== 6 ? () => props.handlePageBack() : null
          }
        />
        <ConfirmButton
          confirm_text={"Confirm"}
          confirm_action={() => props.handleSetUploadDestination()}
        />
      </div>
    </>
  );
};

export const CloudServiceSelection = (props) => {
  return (
    <>
      {props.uploadSource === "local" && props.sequenceType !== null ? (
        <>
          <SelectionRow
            localImages={props.localImages}
            handleSetLocalImages={props.handleSetLocalImages}
            processSubfolders={props.processSubfolders}
            selectedFolder={props.selectedFolder}
            title={"Select Local Image Files:"}
            valueState={props.uploadSource}
            stateHandler={props.handleSetUploadSource}
            fileInputRef={props.fileInputRef}
            inputs={[
              {
                button_text: "Select",
                type: "file",
                fileRef: props.fileInputRef,
              },
            ]}
          />
        </>
      ) : props.uploadSource === "cloud" ? (
        <>
          <SelectionRow
            title={"Select Cloud Service:"}
            valueState={props.cloudService}
            stateHandler={props.handleSetCloudService}
            inputs={[
              {
                type: "radio",
                title: "Amazon S3 (Upcoming)",
                value: "s3",
                isDisabled: true,
              },
              {
                type: "radio",
                title: "Digitalocean Spaces",
                value: "spaces",
              },
            ]}
          />
        </>
      ) : null}
    </>
  );
};

export const CloudUploadSelection = (props) => {
  return (
    <>
      {props.uploadSource === "local" &&
      Object.keys(props.localImages).length > 0 ? (
        <>
          <SelectionRow
            title={"Select Cloud Service:"}
            valueState={props.cloudService}
            stateHandler={props.handleSetCloudService}
            inputs={[
              {
                type: "radio",
                title: "Amazon S3 (Upcoming)",
                value: "s3",
                isDisabled: true,
              },
              {
                type: "radio",
                title: "Digitalocean Spaces",
                value: "spaces",
              },
            ]}
          />
        </>
      ) : null}
    </>
  );
};

export const SelectUploadDestinationModal = (props) => {
  const { newTrips } = useContext(DataContext);
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      if (props.uploadDestinationOpen) {
        props.handleUploadDestinationOpen();
      }
    }
  };

  return (
    <>
      <Modal
        open={props.uploadDestinationOpen}
        key="add"
        onKeyDown={handleKeyDown}
      >
        <ModalWrapper>
          <CloseButton close_action={props.handleUploadDestinationOpen} />
          <SectionTitle
            title_text={<strong>Select Upload Destination</strong>}
          />
          {props.selectionPage === 1 ? (
            <>
              <SelectTripTable
                processSubfolders={props.processSubfolders}
                selectedFolder={props.selectedFolder}
                newTrips={newTrips}
                handleTripSelected={props.handleTripSelected}
                tripSelected={props.tripSelected}
                handleUploadDestinationOpen={props.handleUploadDestinationOpen}
                handlePageBack={props.handlePageBack}
                selectionPage={props.selectionPage}
                handleSetSelectionPage={props.handleSetSelectionPage}
              />
            </>
          ) : props.selectionPage === 2 ? (
            <>
              <SelectVehicleTable
                tripSelected={props.tripSelected}
                tripSelectedName={props.tripSelectedName}
                handleSetSelectedVehicle={props.handleSetSelectedVehicle}
                selectedVehicle={props.selectedVehicle}
                handlePageBack={props.handlePageBack}
                selectionPage={props.selectionPage}
                handleSetSelectionPage={props.handleSetSelectionPage}
              />
            </>
          ) : props.selectionPage === 3 ? (
            <>
              <SelectCameraTable
                tripSelected={props.tripSelected}
                tripSelectedName={props.tripSelectedName}
                selectionPage={props.selectionPage}
                handlePageBack={props.handlePageBack}
                handleSetSelectionPage={props.handleSetSelectionPage}
              />
            </>
          ) : props.selectionPage === 4 ? (
            <>
              <SelectDayTable
                tripSelected={props.tripSelected}
                tripSelectedName={props.tripSelectedName}
                handleSetSelectedVehicle={props.handleSetSelectedVehicle}
                selectedVehicle={props.selectedVehicle}
                selectionPage={props.selectionPage}
                handlePageBack={props.handlePageBack}
                handleSetSelectionPage={props.handleSetSelectionPage}
                setSelectedTripCloudPath={props.setSelectedTripCloudPath}
              />
            </>
          ) : (
            <>
              <ReviewUploadDestinationDetails
                tripSelected={props.tripSelected}
                tripSelectedName={props.tripSelectedName}
                selectionPage={props.selectionPage}
                handlePageBack={props.handlePageBack}
                handleSetSelectionPage={props.handleSetSelectionPage}
                handleSetUploadDestination={props.handleSetUploadDestination}
              />
            </>
          )}
        </ModalWrapper>
      </Modal>
    </>
  );
};

export const UploadDestinationComponent = (props) => {
  const { selectedVehicleName, dayOfTravel, selectedCameraFacing } =
    useContext(DataContext);

  return (
    <>
      {props.filesSelected === true && props.destinationSelected === false ? (
        <>
          <SelectionRow
            title={"Select Upload Destination:"}
            valueState={props.tripType}
            stateHandler={props.handleSetTripType}
            fileInputRef={props.fileInputRef}
            inputs={[
              {
                title: "New Trip (Upcoming)",
                type: "radio",
                value: "new",
                // isDisabled: "true",
              },
              {
                title: "Existing Trip",
                type: "radio",
                value: "existing",
              },

              {
                button_text: props.tripType === "new" ? "Create" : "Select",
                type: "button",
                stateHandler:
                  props.tripType === "new"
                    ? props.handleNewUploadDestinationOpen
                    : props.handleUploadDestinationOpen,
                // props.select_action
              },
            ]}
          />
        </>
      ) : props.filesSelected === true && props.destinationSelected === true ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // backgroundColor: "grey",
              height: "6vh",
              marginTop: "2vh",
            }}
          >
            <SectionTitle
              title_text={"Select Upload Destination:"}
              marginTop={"1vh"}
            />
            <SectionSubtitle
              subtitle_text={
                <>
                  <strong>Trip:</strong> {`${props.tripSelectedName}`}
                </>
              }
              marginTop={"1vh"}
              marginLeft={"0vw"}
            />
            <SectionSubtitle
              subtitle_text={
                <>
                  <strong>Vehicle:</strong> {`${selectedVehicleName}`}
                </>
              }
              marginTop={"1vh"}
              marginLeft={"0vw"}
            />
            <SectionSubtitle
              subtitle_text={
                <>
                  <strong>Camera:</strong> {`${selectedCameraFacing}`}
                </>
              }
              marginTop={"1vh"}
              marginLeft={"0vw"}
            />
            <SectionSubtitle
              subtitle_text={
                <>
                  <strong>Day:</strong> {`${dayOfTravel}`}
                </>
              }
              marginTop={"1vh"}
              marginLeft={"0vw"}
            />
            <SectionSubtitle
              subtitle_text={
                <>
                  <strong>Cloud Path:</strong> {`${props.cloudPath}`}
                </>
              }
              marginTop={"1vh"}
              marginLeft={"0vw"}
            />
            <ConfirmButton
              confirm_text={"Change"}
              confirm_action={() => props.handleChangeDestinationSelected()}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export const ConnectToCloudComponent = (props) => {
  return (
    <>
      {(props.uploadSource === "cloud" && props.cloudService === "s3") ||
      (props.cloudService === "s3" &&
        props.uploadSource === "local" &&
        Object.keys(props.localImages) > 0) ? (
        <>
          {!props.cloudConnected ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "top",
                  height: "20vh",
                  marginLeft: "3vw",
                }}
              >
                {/* <SectionTitle
                  title_text={"Bucket:"}
                  marginTop={"1vh"}
                  paddingLeft={"0vw"}
                  paddingRight={".5vw"}
                />
                <input
                  style={{
                    marginLeft: "0vw",
                    width: "8vw",
                    height: "4vh",
                    marginTop: "1vh",
                  }}
                  type="password"
                  name="name"
                  placeholder="Bucket Name"
                  onChange={(e) => {
                    props.handleSetBucket(e);
                  }}
                /> */}
                {/* <SectionTitle
                  title_text={"Access Token:"}
                  marginTop={"1vh"}
                  paddingLeft={".5vw"}
                  paddingRight={".5vw"}
                />
                <input
                  style={{
                    marginLeft: "0vw",
                    width: "11vw",
                    height: "4vh",
                    marginTop: "1vh",
                  }}
                  type="password"
                  placeholder="Access Token"
                  onChange={(e) => {
                    props.handleSetToken(e);
                  }}
                /> */}
                {/* <SectionTitle
                  title_text={"Secret Key:"}
                  marginTop={"1vh"}
                  paddingLeft={".5vw"}
                  paddingRight={".5vw"}
                />
                <input
                  style={{
                    marginLeft: "0vw",
                    marginRight: "0vw",
                    width: "11vw",
                    height: "4vh",
                    marginTop: "1vh",
                  }}
                  type="password"
                  placeholder="Secret Key"
                  onChange={(e) => {
                    props.handleSetKey(e);
                  }}
                /> */}
                {/* <SectionTitle
                  title_text={"Region:"}
                  marginTop={"1vh"}
                  paddingLeft={".5vw"}
                  paddingRight={".5vw"}
                />
                <div style={{ marginTop: "1vh" }}>
                  <DropdownMenu
                    menu_title={"Regions"}
                    options={props.S3_regions}
                    onSelect={props.handleSetRegion}
                  />
                </div> */}
                <SelectionRow
                  title={"Connect to cloud service:"}
                  stateHandler={props.handleCheckDigitaloceanConnection}
                  inputs={[
                    {
                      title: props.connectingText,
                      type: "text",
                      value: "s3",
                    },
                    !props.connectingText.includes("Attempting") &&
                    !props.connectingText.includes("Connected to Bucket")
                      ? {
                          type: "button",
                          button_text: "Connect",
                        }
                      : {},
                  ]}
                />
              </div>
            </>
          ) : null}
        </>
      ) : (props.uploadSource === "cloud" && props.cloudService === "spaces") ||
        (props.cloudService === "spaces" &&
          props.uploadSource === "local" &&
          Object.keys(props.localImages).length > 0) ? (
        <>
          {!props.cloudConnected ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {/* <SectionTitle title_text={"Bucket:"} marginTop={"1vh"} />
                  <SectionTitle
                    title_text={"Access Token:"}
                    marginTop={"1vh"}
                  />
                  <SectionTitle title_text={"Secret Key:"} marginTop={"1vh"} />
                  <SectionTitle title_text={"Region:"} marginTop={"1vh"} /> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "top",
                    width: "80%",
                  }}
                >
                  {/* <input
                    style={{
                      padding: "8px 10px",
                      border: "1px solid #ccc",
                      borderRadius: "10px",
                      outline: "none",
                      boxShadow: "1px 2px 5px gray",
                    }}
                    type="password"
                    name="name"
                    placeholder="Bucket Name"
                    onChange={(e) => {
                      props.handleSetBucket(e);
                    }}
                  /> */}
                  {/* <input
                    style={{
                      padding: "8px 10px",
                      border: "1px solid #ccc",
                      borderRadius: "10px",
                      outline: "none",
                      boxShadow: "1px 2px 5px gray",

                      marginTop: "1vh",
                    }}
                    type="password"
                    name="name"
                    placeholder="Access Token"
                    onChange={(e) => {
                      props.handleSetToken(e);
                    }}
                  /> */}
                  {/* <input
                    style={{
                      padding: "8px 10px",
                      border: "1px solid #ccc",
                      borderRadius: "10px",
                      outline: "none",
                      boxShadow: "1px 2px 5px gray",
                      marginTop: "1vh",
                    }}
                    type="password"
                    name="name"
                    placeholder="Secret Key"
                    onChange={(e) => {
                      props.handleSetKey(e);
                    }}
                  /> */}
                  {/* <div style={{ marginTop: "1vh" }}>
                    <DropdownMenu
                      menu_title={"Regions"}
                      options={props.S3_regions}
                      onSelect={props.handleSetRegion}
                    />
                  </div> */}
                </div>
              </div>
            </>
          ) : null}
          <SelectionRow
            title={"Connect to cloud service:"}
            // valueState={cloudService}
            stateHandler={props.handleCheckDigitaloceanConnection}
            inputs={[
              {
                title: props.connectingText,
                type: "text",
                value: "s3",
              },
              props.cloudConnected === false
                ? {
                    type: "button",
                    button_text: "Connect",
                  }
                : {},
            ]}
          />
        </>
      ) : null}
    </>
  );
};

export const CameraModelSelectionComponent = (props) => {
  return (
    <>
      {props.mediaType === "video" ? (
        <>
          <SelectionRow
            title={"Camera Model:"}
            valueState={props.cameraModel}
            stateHandler={props.handleSetCameraModel}
            inputs={[
              {
                type: "radio",
                title: "Blackvue Mapillary Edition",
                value: "blackvue",
              },
            ]}
          />
        </>
      ) : props.cameraModel === "images" ? null : props.cameraModel ===
        "360v" ? null : props.cameraModel === "360i" ? null : null}
    </>
  );
};

export const UploadSequenceTypeSelectionComponent = (props) => {
  return (
    <>
      {props.uploadSource !== null ? (
        <>
          <SelectionRow
            title={"Sequence Type:"}
            valueState={props.sequenceType}
            stateHandler={props.handleSetSequenceType}
            inputs={[
              {
                type: "radio",
                title: "Drive Sequence",
                value: "drive",
                // isDisabled: true,
              },
              {
                type: "radio",
                title: "P.O.I. Sequence",
                value: "poi",
              },
            ]}
          />
        </>
      ) : null}
    </>
  );
};

export const UploadSourceSelectionComponent = (props) => {
  return (
    <>
      {props.cameraModel !== null ? (
        <>
          <SelectionRow
            title={"Upload Source:"}
            valueState={props.uploadSource}
            stateHandler={props.handleSetUploadSource}
            inputs={[
              {
                type: "radio",
                title: "Local",
                value: "local",
                // isDisabled: true,
              },
              {
                type: "radio",
                title: "Cloud",
                value: "cloud",
              },
            ]}
          />
        </>
      ) : null}
    </>
  );
};

export const CloudFileSelectionComponent = (props) => {
  return (
    <>
      {props.cloudConnected && props.uploadSource === "cloud" ? (
        <>
          <SelectionRow
            title={"Select Files to Upload:"}
            stateHandler={props.handleSelectCloudFoldersOpen}
            processSubfolders={props.processSubfolders}
            selectedFolder={props.selectedFolder}
            inputs={
              props.filesSelected === false
                ? [
                    {
                      type: "button",
                      button_text: "Select",
                    },
                  ]
                : [
                    {
                      menu_title: "Selected files",
                      type: "dropdown",
                      options:
                        props.processSubfolders === false
                          ? props.selectedFiles
                          : props.selectedFolder,
                    },
                    {
                      type: "button",
                      button_text: "Select",
                    },
                  ]
            }
          />
        </>
      ) : null}
    </>
  );
};

export const CloudDestinationSelectionComponent = (props) => {
  return (
    <>
      {props.cloudConnected && props.uploadSource === "local" ? (
        <>
          <SelectionRow
            title={"Select folder:"}
            stateHandler={props.handleCloudDestinationOpen}
            // processSubfolders={props.processSubfolders}
            selectedFolder={props.selectedFolder}
            inputs={
              props.filesSelected === false
                ? [
                    {
                      type: "button",
                      button_text: "Select",
                    },
                  ]
                : [
                    {
                      menu_title: "Destination path",
                      type: "dropdown",
                      options: props.selectedFolder,
                    },
                    {
                      type: "button",
                      button_text: "Select",
                    },
                  ]
            }
          />
        </>
      ) : null}
    </>
  );
};

export const SelectCloudFolderModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation(); // Stop the event from propagating to parent components
      if (props.selectCloudFoldersOpen) {
        props.handleSelectCloudFoldersOpen(); // Call the function to close the modal
      }
    }
  };

  return (
    <>
      {props.cloudConnected === true ? (
        <>
          <Modal
            open={props.selectCloudFoldersOpen}
            key="add"
            onKeyDown={handleKeyDown}
          >
            <ModalWrapper>
              <CloseButton close_action={props.handleSelectCloudFoldersOpen} />
              <SectionTitle
                title_text={<strong>Select Files to Upload:</strong>}
              />
              <SectionSubtitle subtitle_text={`Path: ${props.cloudPath}`} />

              <>
                <div>
                  <style>{spinAnimation}</style>
                  {props.isLoading ? (
                    <div style={spinnerStyle}>
                      <div style={circleStyle}></div>
                      Loading...
                    </div>
                  ) : (
                    <div>
                      {
                        <>
                          <TableCard
                            style={{
                              boxShadow: "1px 1px 6px 2px gray",
                              width: "90%",
                              height: "60vh",
                              margin: "auto",
                              marginBottom: "3vh",
                              marginTop: "3vh",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                marginTop: "2vh",
                                width: "20vw",
                                height: "54vh",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <SectionSubtitle
                                  subtitle_text={<strong>Folders:</strong>}
                                />
                                <input
                                  type="checkbox"
                                  checked={props.processSubfolders === true}
                                  onChange={() =>
                                    props.handleToggleProcessSubfolders()
                                  }
                                />
                                <SectionSubtitle
                                  subtitle_text={"Process subfolders"}
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "50vh",
                                  overflowY: "scroll",
                                  alignItems: "left",
                                }}
                              >
                                {props.cloudFolderContents &&
                                  props.cloudFolderContents.map((row) => {
                                    return (
                                      <div
                                        key={row}
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          marginBottom: "2vh",
                                          backgroundColor:
                                            props.selectedFolder === row
                                              ? "lightblue"
                                              : "",
                                        }}
                                        onClick={() =>
                                          props.handleSetSelectedFolder(row)
                                        }
                                        onDoubleClick={() =>
                                          props.handleSetCloudPath(row)
                                        }
                                      >
                                        <ProjectIcon
                                          style={{
                                            backgroundColor: "transparent",
                                          }}
                                          key={row}
                                          src={folder_icon}
                                        />
                                        {row}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                            <div
                              style={{
                                marginTop: "2vh",
                                width: "20vw",
                                height: "55vh",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    props.handleSetAllFilesSelected()
                                  }
                                  checked={
                                    props.selectedFiles ===
                                    props.cloudFileContents
                                  }
                                  style={{
                                    marginLeft: "2vw",
                                    marginRight: "0vw",
                                  }}
                                />

                                <SectionSubtitle
                                  subtitle_text={<strong>Files:</strong>}
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "50vh",
                                  overflowY: "scroll",
                                  alignItems: "left",
                                }}
                              >
                                {props.cloudFileContents.map((row) => {
                                  return (
                                    <div
                                      key={row + "1"}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginLeft: "2vw",
                                        marginTop: "1vh",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        key={row + "2"}
                                        value={row}
                                        checked={props.selectedFiles.includes(
                                          row
                                        )}
                                        onChange={(e) =>
                                          props.handleSetSelectedFiles(e)
                                        }
                                      />
                                      <span
                                        key={row + "3"}
                                        style={{ width: "1vw" }}
                                      />
                                      {row}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </TableCard>
                        </>
                      }
                    </div>
                  )}
                </div>
              </>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "3vh",
                }}
              >
                {props.backButton && props.isLoading === false ? (
                  <ConfirmButton
                    confirm_text={"Back"}
                    confirm_action={() => props.handleSetCloudPathBack()}
                  />
                ) : null}
                {props.isLoading === false &&
                props.cloudFileContents.length > 0 ? (
                  <>
                    <ConfirmButton
                      confirm_text={"Select"}
                      confirm_action={() => props.handleSelectFiles()}
                    />
                  </>
                ) : null}
                {props.processSubfolders === true ? (
                  <>
                    <ConfirmButton
                      confirm_text={"Select"}
                      confirm_action={() => props.handleSelectFiles()}
                    />
                  </>
                ) : null}
              </div>
            </ModalWrapper>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export const ProceedWithUploadComponent = (props) => {
  return (
    <>
      {props.destinationSelected ? (
        <>
          <SelectionRow
            title={"Proceed with Upload:"}
            // valueState={cloudService}

            inputs={[
              {
                type: "button",
                button_text: "Cancel",
                stateHandler: props.handleCancelUpload,
              },
              {
                type: "button",
                button_text: "Proceed",
                stateHandler: props.handleProcessCloudFiles,
              },
            ]}
          />
        </>
      ) : null}
    </>
  );
};

export const SelectCloudDestinationModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      if (props.selectCloudFoldersOpen) {
        props.handleSelectCloudFoldersOpen();
      }
    }
  };

  return (
    <>
      {props.cloudConnected === true && props.uploadSource === "local" ? (
        <>
          <Modal
            open={props.cloudDestinationOpen}
            key="add"
            onKeyDown={handleKeyDown}
          >
            <ModalWrapper>
              <CloseButton close_action={props.handleCloudDestinationOpen} />
              <SectionTitle
                title_text={<strong>Select Upload Destination:</strong>}
              />
              <SectionSubtitle subtitle_text={`Path: ${props.cloudPath}`} />

              <>
                <div>
                  <style>{spinAnimation}</style>
                  {props.isLoading ? (
                    <div style={spinnerStyle}>
                      <div style={circleStyle}></div>
                      Loading...
                    </div>
                  ) : (
                    <div>
                      {
                        <>
                          <TableCard
                            style={{
                              boxShadow: "1px 1px 6px 2px gray",
                              width: "90%",
                              height: "60vh",
                              margin: "auto",
                              marginBottom: "3vh",
                              marginTop: "3vh",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                marginTop: "2vh",
                                width: "20vw",
                                height: "54vh",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <SectionSubtitle
                                  subtitle_text={<strong>Folders:</strong>}
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "50vh",
                                  overflowY: "scroll",
                                  alignItems: "left",
                                }}
                              >
                                {props.cloudFolderContents &&
                                  props.cloudFolderContents.map((row) => {
                                    return (
                                      <div
                                        key={row}
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          marginBottom: "2vh",
                                          backgroundColor:
                                            props.selectedFolder === row
                                              ? "lightblue"
                                              : "",
                                        }}
                                        onClick={() =>
                                          props.handleSetSelectedFolder(row)
                                        }
                                        onDoubleClick={() =>
                                          props.handleSetCloudPath(row)
                                        }
                                      >
                                        <ProjectIcon
                                          key={row}
                                          src={folder_icon}
                                        />
                                        {row}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                            <div
                              style={{
                                marginTop: "2vh",
                                width: "20vw",
                                height: "55vh",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <SectionSubtitle
                                  subtitle_text={<strong>Files:</strong>}
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "50vh",
                                  overflowY: "scroll",
                                  alignItems: "left",
                                }}
                              >
                                {props.cloudFileContents.map((row) => {
                                  return (
                                    <div
                                      key={row + "1"}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginLeft: "2vw",
                                        marginTop: "1vh",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        key={row + "2"}
                                        value={row}
                                        checked={props.selectedFiles.includes(
                                          row
                                        )}
                                        onChange={(e) =>
                                          props.handleSetSelectedFiles(e)
                                        }
                                      />
                                      <span
                                        key={row + "3"}
                                        style={{ width: "1vw" }}
                                      />
                                      {row}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </TableCard>
                        </>
                      }
                    </div>
                  )}
                </div>
              </>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "3vh",
                }}
              >
                {props.backButton && props.isLoading === false ? (
                  <ConfirmButton
                    confirm_text={"Back"}
                    confirm_action={() => props.handleSetCloudPathBack()}
                  />
                ) : null}
                {props.isLoading === false &&
                props.cloudFileContents.length > 0 ? (
                  <>
                    <ConfirmButton
                      confirm_text={"Select"}
                      confirm_action={() => props.handleSelectFiles()}
                    />
                  </>
                ) : null}
                <>
                  <ConfirmButton
                    confirm_text={"New Folder"}
                    confirm_action={() => props.handleSelectFiles()}
                  />
                </>
              </div>
            </ModalWrapper>
          </Modal>
        </>
      ) : null}
    </>
  );
};

// const NewFolderModal=()=>{

//   return(
//     <Modal
//     open={props.cloudDestinationOpen}
//     key="add"
//     onKeyDown={handleKeyDown}
//   >
//     <ModalWrapper>
//   )

// }
