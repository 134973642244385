import React from "react";
import { BaseModal } from "../../composite/BaseModal";

export const DeleteUserModal = (props) => (
  <BaseModal
    isOpen={props.deleteOpen}
    onClose={props.handleDeleteOpen}
    title="Remove a user"
    subtitle={
      <>
        User: {<strong>{props.userSelectedName}</strong>} - will be removed from
        your organization
      </>
    }
    confirmText="Remove"
    onConfirm={() => props.do_remove_user(props.form)}
  />
);
