import styled from "styled-components";
import arrow_left from "../../../../images/arrow_left_black.png";
import play from "../../../../images/start_autoplay_black.png";
import arrow_right from "../../../../images/arrow_right_black.png";
import stop_icon from "../../../../images/stopButton.png";

export const ArrowBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 6px;
  height: 4.5vh;
  width: 25vw;
  box-shadow: 1px 1px 4px 1px black;
  background: rgba(66, 71, 75, 0.6);
`;

// export const DoubleArrowLeft = styled.div`
//   background-image: url(${end_left});
//   background-repeat: no-repeat;
//   background-position: 50%;
//   background-size: 22px 22px;
//   left: 0;
//   width: 10%;
//   height: 100%;
//   margin: auto;

//   &:hover {
//     cursor: pointer;
//     background-color: #91a5ac;
//     border-radius: 6px;
//   }
// `;

// export const DoubleChevronLeft = styled.div`
//   background-image: url(${double_arrow_left});
//   background-repeat: no-repeat;
//   background-position: 50%;
//   background-size: 22px 22px;
//   left: 15%;
//   width: 10%;
//   height: 100%;
//   margin: auto;

//   &:hover {
//     cursor: pointer;
//     background-color: #91a5ac;
//     border-radius: 2px;
//   }
// `;

export const ChevronLeft = styled.div`
  background-image: url(${arrow_left});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 22px 22px;
  left: 30%;
  width: 10%;
  height: 100%;
  margin: auto;

  &:hover {
    cursor: pointer;
    background-color: #91a5ac;
    border-radius: 2px;
  }
`;

export const Play = styled.div`
  background-image: url(${play});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 22px 22px;
  /* left: 45%; */
  width: 10%;
  height: 100%;
  margin: auto;

  &:hover {
    cursor: pointer;
    background-color: #91a5ac;
    border-radius: 2px;
  }
`;

export const Stop = styled.div`
  background-image: url(${stop_icon});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 22px 22px;
  /* left: 45%; */
  width: 10%;
  height: 100%;
  margin: auto;

  &:hover {
    cursor: pointer;
    background-color: #91a5ac;
    border-radius: 2px;
  }
`;

export const ChevronRight = styled.div`
  background-image: url(${arrow_right});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 22px 22px;
  left: 60%;
  width: 10%;
  height: 100%;
  margin: auto;

  &:hover {
    cursor: pointer;
    background-color: #91a5ac;
    border-radius: 2px;
  }
`;

// export const DoubleChevronRight = styled.div`
//   background-image: url(${double_arrow_right});
//   background-repeat: no-repeat;
//   background-position: 50%;
//   background-size: 22px 22px;
//   left: 75%;
//   width: 10%;
//   height: 100%;
//   margin: auto;

//   &:hover {
//     cursor: pointer;
//     background-color: #91a5ac;
//     border-radius: 2px;
//   }
// `;

// export const DoubleArrowRight = styled.div`
//   background-image: url(${end_right});
//   background-repeat: no-repeat;
//   background-position: 50%;
//   background-size: 22px 22px;
//   left: 90%;
//   width: 10%;
//   height: 100%;
//   margin: auto;

//   &:hover {
//     cursor: pointer;
//     background-color: #91a5ac;
//     border-radius: 6px;
//   }
// `;
