import { poster } from "../calls";

export async function getImageUrl(image_id) {
  try {
    return await poster(
      {
        image_id: image_id,
      },
      "sequences/fetch_img_url"
    );
  } catch (error) {
    console.error(error);
  }
}

export async function getImage(selectedImage) {
  try {
    return await poster(
      {
        image_id: selectedImage,
      },
      "sequences/fetch_image_details"
    );
  } catch (error) {
    console.error(error);
  }
}

export async function getImagesBySequenceId(sequence_id) {
  try {
    return await poster(
      {
        sequence_id: sequence_id,
      },
      "sequences/fetch_sequence_images"
    );
  } catch (error) {
    console.error(error);
  }
}

export async function getImageUrlsBySequenceId(sequence_id) {
  try {
    return await poster(
      {
        sequence_id: sequence_id,
      },
      "sequences/fetch_selected_images_urls"
    );
  } catch (error) {
    console.error(error);
  }
}

export async function deleteImage(image_id) {
  try {
    return await poster(
      {
        image_id: image_id,
      },
      "sequences/delete_image"
    );
  } catch (error) {
    console.error(error);
  }
}
