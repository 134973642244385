import styled from "styled-components";
import kaartLogo from "../../../images/logo-kaart-standard.svg";

export const MenuItem = styled.a`
  position: relative;
  color: #253e45 !important;
  padding: 1rem;
  display: block;
  text-decoration: none;
  box-sizing: border-box;
  &:hover {
    cursor: pointer;
    background: rgba(145, 165, 172, 0.1);
  }
`;

export const MenuItemTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const KaartLogoOpen = styled.div`
  background-image: url(${kaartLogo});
  background-repeat: no-repeat;
  background-position: 50%;
  max-width: 100%;
  width: 100px;
  height: 80px;
  &:hover {
    cursor: pointer;
  }
`;

export const ProjectIconContainer = styled.span`
  border-radius: 50%;
  height: 16px;
  width: 16px;

  text-align: center;
  box-sizing: border-box;
  font-size: h3;
`;

export const SidebarIcon = styled.img`
  width: 35px;
  height: 35px;
`;

export const Header = styled.a`
  text-align: left;
  margin-left: 1.2vw;
  color: #253e45 !important;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  background-color: transparent !important;
  border-right: none !important;
`;

export const RoleBarWrapper = styled.div`
  /* padding-top: 0.5vw; */
  text-align: center;
  margin-bottom: 1rem;
  height: 4rem;
  width: 16rem;
  // background: rgba(145, 165, 172, 0.2);
  border-radius: 6px;
`;

export const RoleHeader = styled.h6`
  /* text-align: center; */
  /* font-size: 1.1rem; */
  padding-top: 5px;
  color: #253e45;
  font-weight: 700;
`;

export const RoleSubHeader = styled.h6`
  /* text-align: center; */
  color: #253e45;
  opacity: 0.7;
`;
